import React from 'react';

import {colors} from 'GettingStarted/ColorModule';

import Rod from './Inc/Rod';
import Space from './Inc/Space';
import BadSpace from './Inc/BadSpace';
import RodSupplement from './Inc/RodSupplement';
import { SUPPLEMENT_SPACE_PLATE_MIN_HEIGHT } from 'App';

const Rods = ({
                design,
                scale,
                projectType,
                fenceType = null,
              }) => {
  const {
    moduleHeight, moduleColor,
  } = design;
  const color = moduleColor ? colors.filter((c) => c.label === moduleColor) : null;
  const rods = design.rodsStructure.map((struct, index) => {
    return <div key={Math.random()}>
      <Rod
        index={index + 1}
        size={struct.size}
        scale={scale}
        module={!!moduleHeight}
        color={color ? color[0].color : null}
        fenceType={fenceType}
      />
      <Space space={struct.space} scale={scale} projectType={projectType} fenceType={fenceType}/>
    </div>
  });

  if (design.supplementSpace < SUPPLEMENT_SPACE_PLATE_MIN_HEIGHT) {
    rods.push(<BadSpace key={Math.random()} scale={scale} space={design.supplementSpace}/>);
  } else {
    const automationSupported = ['gateTilting', 'singleLeafGate', 'foldingGate'].includes(gateType);
    const automation = automationSupported && design?.automation.availableOnRodSupplementRod;
    rods.push(<RodSupplement
      key={Math.random()}
      scale={scale}
      size={design.supplementSpace}
      fenceType={fenceType}
      automation={automation}
    />);
  }

  const width = projectType === 'span' ? '75%' : '100%';

  return (
    <div style={{width}}>
      {rods}
    </div>
  );
};

export default Rods;
