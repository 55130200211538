import React from 'react';
import {useFormikContext} from 'formik';

import {hinges, HINGES_180, HINGES_STANDARD} from 'fenceTypesConstants';
import {OPTION_P302_WITH_MODULE} from 'App';
import {Error, InputNumber} from 'components/Input';
import {onPropertyChange} from 'Design/Design';
import Automation from 'Project/Top/TopGate/Automation';

import SupplementSpace from './SupplementSpace';

import {
  GreyInputsContainer, HingesSelect, InputsContainer, Label
} from '../Project';
import { useTranslation } from 'react-i18next';

const BottomGate = () => {
  const formik = useFormikContext();
  const { t } = useTranslation();

  if (formik.values.gate.chosen && formik.values.gate.gateType) {
    return (
      <>
        <GreyInputsContainer automation={formik.values.gate.automation.checked}>
          <div className="form-group">
            <InputNumber
              change={(v) => onPropertyChange(formik, 'gate', 'space', v)}
              val={formik.values.gate.space}
              info={formik.touched.gate?.space && formik.errors.gate?.space}
              label={t('spaceInput')}
              name="gate.space"
              onBlur={formik.handleBlur}
            />
          </div>
          <SupplementSpace type="gate"/>
          <Automation/>
        </GreyInputsContainer>
        <InputsContainer>
          {formik.values.option === OPTION_P302_WITH_MODULE && (
            <>
              <div className="form-group">
                <InputNumber
                  change={(v) => onPropertyChange(formik, 'gate', 'moduleHeight', v)}
                  val={formik.values.gate.moduleHeight}
                  info={formik.touched.gate?.moduleHeight && formik.errors.gate?.moduleHeight}
                  label={t('moduleHeightInput')}
                  name="gate.moduleHeight"
                  onBlur={formik.handleBlur}

                />
              </div>
            </>
          )}
          <div className="form-group">
            <InputNumber
              change={(v) => onPropertyChange(formik, 'gate', 'amount', v)}
              val={formik.values.gate.amount}
              info={formik.touched.gate?.amount && formik.errors.gate?.amount}
              label={t('gatesAmountInput')}
              name="gate.amount"
              onBlur={formik.handleBlur}

            />
          </div>
          {(formik.values.gate.gateType === 'gateTilting' || formik.values.gate.gateType === 'singleLeafGate' || formik.values.gate.gateType === 'nonSymmetricalGate') && (
            <div className="form-group">
              <Label id="gateTiltingHinges">{t('hinges')}</Label>
              <HingesSelect
                className="form-control"
                onChange={(e) => onPropertyChange(formik, 'gate', 'hinges', e.currentTarget.value)}
                value={formik.values.gate.hinges}
                name="gate.hinges"
                onBlur={formik.handleBlur}
              >
                <option value="" disabled>{t('chooseHingesInput')}</option>
                <option value={HINGES_STANDARD}>{t('standardHinges')}</option>
                <option value={HINGES_180}>{t('180Hinges')}</option>
              </HingesSelect>
            </div>
          )}
          <Error>{formik.touched.gate?.hinges && formik.errors.gate?.hinges && formik.errors.gate.hinges}</Error>
          {(formik.values.gate.gateType === 'foldingGate') && (
            <div className="form-group">
              <Label id="foldingGateMountingStrip">{t('mountingStrip')}</Label>
              <HingesSelect
                className="form-control"
                onChange={(e) => onPropertyChange(formik, 'gate', 'mountingStrip', e.currentTarget.value)}
                value={formik.values.gate.mountingStrip}
                name="gate.mountingStrip"
                onBlur={formik.handleBlur}
              >
                <option value="0">0</option>
                <option value="1">1</option>
                <option value="2">2</option>
              </HingesSelect>
            </div>
          )}
        </InputsContainer>
      </>
    );
  }
  return null;
};

export default BottomGate;
