import React from 'react';
import styled from 'styled-components';
import {useFormikContext} from 'formik';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
  margin-left: 4rem;
`;

const Desc = styled.span`
  font-weight: bold;
  margin-top: 4rem;
  margin-bottom: 1rem;
  display: inline-block;
  font-size: 1.2rem;
`;

const Buttons = styled.div`
  display: flex;
`;

const Button = styled.button`
  background: ${props => props.color};
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  padding: 1.5rem 1.5rem 4rem 1.5rem;
  width: 19.5rem;
  margin-right: 1.4rem;
`;

const ButtonTitle = styled.span`
  color: #fff;
  font-size: 2rem;
`;

const Color = () => {
  const formik = useFormikContext();
  const { t } = useTranslation();

  if (formik.values.color) return null;
  return (
    <Container>
      <Desc>{t('sheetColor')}</Desc>
      <Buttons>
        <Button type="button" color="#38393b" onClick={() => formik.setFieldValue('color', 'Grafitowy')}
                data-sentry="chooseColor"
        >
          <ButtonTitle>{t('colorGraphite')}</ButtonTitle>
        </Button>
        <Button type="button" color="#372a27" onClick={() => formik.setFieldValue('color', 'Brązowy')}
                data-sentry="chooseColor"
        >
          <ButtonTitle>{t('colorBrown')}</ButtonTitle>
        </Button>
      </Buttons>
    </Container>
  );
};

export default Color;
