import PropTypes from 'prop-types';

const wrapPdfWithHtml = (body) => `
  <html lang="pl">
    <head>
      <meta charset="utf-8">
      <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css" integrity="sha384-Vkoo8x4CGsO3+Hhxv8T/Q5PaXtkKtu6ug5TOeNV6gBiFeWPGFN9MuhOf23Q9Ifjh" crossOrigin="anonymous" />
      <style>
      .new-page {
        page-break-before: always;
        padding: 4rem 0 2rem 0;
       }
       
       .generalInformation {
        margin-top: 4rem;
        font-size: 1.2rem;
        text-align: justify;
       }

      .importantSpan {
        color: #ff0000;
        text-transform: uppercase;
        font-size: 1.4rem;
      }
      
      h2 {
        font-size: 4rem;
        font-weight: lighter;
      }
      
      h3 {
        font-size: 2.75rem;
        font-weight: lighter;
        margin-top: 2rem;
      }
      
      .projectName {
        font-size: 1.5rem;
        text-align: right;
      }
      
      .headerLine {
        width: 100%;
        height: 2px;
        background: #e0e0e0;
        margin-bottom: 3rem;
      }
      
      .fenceVisualisation img {
        margin-left: auto;
        margin-right: auto;
        display: block;
        height: 350px;
        width: auto;     
        margin-bottom: 50px; 
      }
      
      .fenceVisualisation {
        margin-bottom: 2rem;
      }
      
      .fenceTypeData p, .sumUpData p {
        font-size: 1.5rem;
        margin-left: 1rem;
      }
      
      .platesWidthKey {
        font-size: 15px;
        margin-top: 1.5rem;        
      }
         
      .pageInner {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 1300px;
      }   
      </style>
    </head>
    <body>
      ${body}
    </body>
  </html>
`;

wrapPdfWithHtml.propTypes = {
  body: PropTypes.string.isRequired,
};

export default wrapPdfWithHtml;
