import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCircle} from '@fortawesome/free-regular-svg-icons';
import {faCircle as currentIcon} from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';

const Nav = ({slides, current, setCurrent}) => {
  const rendered = slides.map((s, i) => {
    const icon = i === current ? currentIcon : faCircle;
    return <Button key={s.src} className="btn" type="button" onClick={() => setCurrent(i)}>
      <Icon icon={icon}/>
    </Button>
  })

  return (
    <Container>
      {rendered}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const Button = styled.button`
  padding: 0;
  margin: 0 .5rem;
  &:focus {
    box-shadow: none;
  }
`;

const Icon = styled(FontAwesomeIcon)`
`;

export default Nav;
