import React from 'react';
import {useFormikContext} from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
import { useTranslation, Trans } from 'react-i18next';

import {changeModel, OPTION_P302, SUPPLEMENT_SPACE_PLATE_MIN_HEIGHT} from 'App';

import {
  StartButton, StartCenterContainer,
  StartConditionsContainer,
  StartGatewayContainer, StartGoBackBtnContainer, StartImageContainer,
  StartListItem,
  StartListParagraph,
  StartPageHeaderContainer, StartPageHeaderTopRow, StartTopRowLeft, StartTypeContainer,
  StartTypeTitle, StartContainer,
} from 'TermsModule';
import InfoGatewayPopup from 'Pdf/SummaryPage/InfoGateway';
import InfoGatePopup from 'Pdf/SummaryPage/InfoGate';
import InfoGateTiltingPopup from 'Pdf/SummaryPage/InfoGateTilting';

const StartRulesContainer = styled.ul`
  margin-right: auto;
  min-height: 425px;
  overflow: auto;
  margin-left: -1rem;
`;

const GatewayImg = styled.img`
  height: 130px;
  width: auto;
`;

const GateTiltingImg = styled.img`
  height: 130px;
  width: auto;
`;

const GateSelfSupportingImg = styled.img`
  height: 130px;
  width: auto;
`;

const GatewayConditions = () => (
  <>
    <StartTypeTitle><Trans>gateway</Trans></StartTypeTitle>
    <StartConditionsContainer style={{ flexDirection: 'column' }}>
      <StartRulesContainer>
        <StartListItem>
          <StartListParagraph>
            <b><Trans>firstPlate</Trans></b>
            <Trans>firstPlateGatewayDefault</Trans>
          </StartListParagraph>
        </StartListItem>
        <StartListItem>
          <StartListParagraph>
            <b><Trans>spaceBetweenLastPlateAndBottom</Trans></b>
            <Trans i18nKey='spaceBetweenLastPlateAndBottomCondition'>{{SUPPLEMENT_SPACE_PLATE_MIN_HEIGHT}}</Trans>
          </StartListParagraph>
        </StartListItem>
        <StartListItem>
          <StartListParagraph>
            <Trans>standardWidthDimensions</Trans>
            <b> <Trans>gatewayLockAndHinge</Trans></b>
          </StartListParagraph>
        </StartListItem>
      </StartRulesContainer>
      <InfoGatewayPopup />
      <StartGatewayContainer>
        <GatewayImg src="img/conditions/gateway.png" alt="" />
      </StartGatewayContainer>
    </StartConditionsContainer>
  </>
);

const GateTiltingConditions = () => (
  <>
    <StartTypeTitle><Trans>gateTilting</Trans></StartTypeTitle>
    <StartConditionsContainer style={{ flexDirection: 'column' }}>
      <StartRulesContainer>
        <StartListItem>
          <StartListParagraph>
          <b><Trans>firstPlate</Trans></b>
            <Trans>firstPlateTiltingDefault</Trans>
          </StartListParagraph>
        </StartListItem>
        <StartListItem>
          <StartListParagraph>
            <b><Trans>spaceBetweenLastPlateAndBottom</Trans></b>
            <Trans i18nKey='spaceBetweenLastPlateAndBottomCondition'>{{SUPPLEMENT_SPACE_PLATE_MIN_HEIGHT}}</Trans>
          </StartListParagraph>
        </StartListItem>
        <StartListItem>
          <StartListParagraph>
            <Trans>standardWidthDimensions</Trans>
            <b> <Trans>gateClamsAndLeavesGap</Trans></b>
          </StartListParagraph>
        </StartListItem>
      </StartRulesContainer>
      <InfoGateTiltingPopup />
      <StartImageContainer>
        <GateTiltingImg src="img/conditions/gateTilting.png" alt=""/>
      </StartImageContainer>
    </StartConditionsContainer>
  </>
);

const GateSelfSupportingConditions = () => (
  <>
    <StartTypeTitle><Trans>gateSelfSupporting</Trans></StartTypeTitle>
    <StartConditionsContainer style={{ flexDirection: 'column' }}>
      <StartRulesContainer>
        <StartListItem>
          <StartListParagraph>
            <b><Trans>firstPlate</Trans></b>
            <Trans>firstPlateSelfSupDefault</Trans>
          </StartListParagraph>
        </StartListItem>
        <StartListItem>
          <StartListParagraph>
            <b><Trans>spaceBetweenLastPlateAndBottom</Trans></b>
            <Trans i18nKey='spaceBetweenLastPlateAndBottomCondition'>{{SUPPLEMENT_SPACE_PLATE_MIN_HEIGHT}}</Trans>
          </StartListParagraph>
        </StartListItem>
        <StartListItem>
          <StartListParagraph>
            <Trans>selfSupportingGateCondition</Trans>
            <b> <Trans>selfSuportingGateConditionRail</Trans></b>
          </StartListParagraph>
        </StartListItem>
        <StartListItem>
          <StartListParagraph>
            <b><Trans>selfSuportingGateConditionRailHeight</Trans></b>
            <Trans>selfSuportingGateConditionRailHeightIs</Trans>
          </StartListParagraph>
        </StartListItem>
      </StartRulesContainer>
      <InfoGatePopup />
      <StartImageContainer>
        <GateSelfSupportingImg src="img/conditions/gateSelfSupporting.png" alt=""/>
      </StartImageContainer>
    </StartConditionsContainer>
  </>
);

const Terms = () => {
  const formik = useFormikContext();
  const { t } = useTranslation();

  if (formik.values.termsAccepted || formik.values.option !== OPTION_P302 ||
    !formik.values.color) return null;
  return (
    <StartContainer className="container-xxl">
      <div className="row">
        <div className="col">
          <StartPageHeaderContainer>
            <div>
              <h5>{t("projectGuidelines")}</h5>
            </div>
            <StartPageHeaderTopRow>
              <StartTopRowLeft>
                <h1><b>{t("fenceP302")}</b></h1>
                <StartGoBackBtnContainer>
                  <StartButton className="btn btn-secondary" href="/">
                    {t("modelChange")}
                  </StartButton>
                </StartGoBackBtnContainer>
              </StartTopRowLeft>
              <div>
                <StartButton className="btn btn-success" onClick={() => formik.setFieldValue('termsAccepted', true)}>
                  {t('guidelinesAccept')}
                  <FontAwesomeIcon
                    icon={faChevronRight}
                    style={{ color: '#ffffff', marginLeft: '1rem' }}
                  />
                </StartButton>
              </div>
            </StartPageHeaderTopRow>
          </StartPageHeaderContainer>
          <StartCenterContainer>
            <StartTypeContainer>
              <GatewayConditions />
            </StartTypeContainer>
            <StartTypeContainer>
              <GateTiltingConditions />
            </StartTypeContainer>
            <StartTypeContainer>
              <GateSelfSupportingConditions />
            </StartTypeContainer>
          </StartCenterContainer>
        </div>
      </div>
    </StartContainer>
  );
};

export default Terms;
