import React from 'react';
import {useFormikContext} from 'formik';
import styled from 'styled-components';

import {STANDARD_PLATE_HEIGHT, SUPPLEMENT_SPACE_PLATE_MIN_HEIGHT} from 'App';
import {LAST_PLATE_INFO} from 'fenceTypesConstants';
import { useTranslation } from 'react-i18next';
import i18next from '../../Translation/config';

const lastPlateTranslation = i18next.language === 'pl' ? 'Ostatnia płyta w projekcie ma' : 'Last plate in project has'
export const LastPlate = ({value, type}) => {
  return <Container>
    <p>{lastPlateTranslation} <Span>{value} mm.</Span></p>
    {type === 'span' && value !== STANDARD_PLATE_HEIGHT && <p>{LAST_PLATE_INFO}</p>}
  </Container>
}

const ISSUE_DEFAULT = 'ISSUE_DEFAULT';
const ISSUE_TO_HIGH = 'ISSUE_TO_HIGH';

export const calculateMissingSpace = ({space, supplementSpace}) => {
  if (!supplementSpace) return null;
  if (supplementSpace < SUPPLEMENT_SPACE_PLATE_MIN_HEIGHT) {
    return {
      'issue': ISSUE_DEFAULT,
      'value': SUPPLEMENT_SPACE_PLATE_MIN_HEIGHT - supplementSpace,
    };
  }
  if (supplementSpace > STANDARD_PLATE_HEIGHT) {
    return {
      'issue': ISSUE_TO_HIGH,
      'value': STANDARD_PLATE_HEIGHT + space - supplementSpace,
    };
  }
  return null;
}

const SupplementSpace = ({type}) => {
  const formik = useFormikContext();
  const { t } = useTranslation();
  const {renderDesign, supplementSpace, space} = formik.values[type];
  if (!renderDesign || !supplementSpace) {
    return <div className="col"/>
  }
  const missingSpace = calculateMissingSpace({space, supplementSpace});
  if (missingSpace && missingSpace.issue === ISSUE_DEFAULT) {
    return (
      <div className="col">
        <ProjectInfo>
          <RedSpan>{t('fixProject')}</RedSpan> {t('lastPlateHeight')}: <RedSpan>{supplementSpace} mm.</RedSpan>
          <br/>
          {t('missingValue')} <RedSpan>{missingSpace.value} mm.</RedSpan>
        </ProjectInfo>
      </div>
    );
  }
  if (missingSpace && missingSpace.issue === ISSUE_TO_HIGH) {
    return (
      <div className="col">
        <ProjectInfo>
          <RedSpan>{t('fixProject')}</RedSpan> {t('lastPlateIsTooLow')} <RedSpan>{supplementSpace - STANDARD_PLATE_HEIGHT} mm </RedSpan> {t('tooTall')}.
          <br/>
          {t('missingValue')} <RedSpan>{missingSpace.value} mm.</RedSpan>
        </ProjectInfo>
      </div>
    );
  }
  return <LastPlate value={supplementSpace} type={type}/>
};

const Span = styled.span`
  font-weight: bold;
  color: #000000;
`;

const Container = styled.div`
  margin-top: 1rem;
  font-size: 0.875rem;
`;

const ProjectInfo = styled.p`
  margin-top: 1rem;
  font-size: 0.875rem;
`;

const RedSpan = styled.span`
  font-weight: bold;
  color: #ff0000;
`;

export default SupplementSpace;
