import React from 'react';
import styled from 'styled-components';

import {
  FENCE_TYPE_GATE_SELF_SUPPORTING, FENCE_TYPE_GATE_TILTING,
  FENCE_TYPE_GATEWAY,
  FENCE_TYPE_SPAN,
} from 'App';


export const Container = styled.div`
  opacity: .5;
  background: #0096FF;
`;

const Space = ({
  space, scale, projectType, fenceType = null, spaceType = 'spaceProject',
}) => {
  const spaceScaled = space / scale;

  const getMargin = () => {
    if (spaceType === 'spaceProject') {
      if (projectType === FENCE_TYPE_SPAN) {
        return '-5.5rem';
      }
      if (projectType === FENCE_TYPE_GATEWAY) {
        return '-8rem';
      }
      if (fenceType === FENCE_TYPE_GATE_SELF_SUPPORTING) {
        return '-7.25rem';
      }
      if (fenceType === FENCE_TYPE_GATE_TILTING) {
        return '-8.15rem';
      }
    }
    return 'none';
  };

  const getWidth = () => {
    if (spaceType === 'spaceProject') {
      if (projectType === FENCE_TYPE_SPAN) {
        return '145%';
      }
      if (projectType === FENCE_TYPE_GATEWAY) {
        return '186%';
      }
      if (fenceType === FENCE_TYPE_GATE_SELF_SUPPORTING) {
        return '145%';
      }
      if (fenceType === FENCE_TYPE_GATE_TILTING) {
        return '187%';
      }
    }
    return '100%';
  };

  const positionAndIndexStyle = () => {
    if (spaceType === 'spaceProject') {
      return { position: 'relative', zIndex: '12' };
    }
    return null;
  };

  return (
    <Container
      style={{
        height: spaceScaled,
        marginLeft: getMargin(),
        width: getWidth(),
        positionAndIndexStyle,
      }}
    />
  );
};

export default Space;
