import React from 'react';
import {useFormikContext} from 'formik';

import {OPTION_P302_WITH_MODULE} from 'App';
import {onPropertyChange} from 'Design/Design';
import {InputNumber} from 'components/Input';

import {GreyInputsContainer, InputsContainer} from '../Project';
import SupplementSpace from './SupplementSpace';
import { useTranslation } from 'react-i18next';

const BottomSpan = () => {
  const formik = useFormikContext();
  const { t } = useTranslation();

  if (!formik.values.span.chosen) return null;
  return (
    <>
      <GreyInputsContainer automation={formik.values.gate.automation.checked}>
        <div className="form-group">
          <InputNumber
            change={(v) => onPropertyChange(formik, 'span', 'space', v)}
            val={formik.values.span.space}
            info={formik.touched.span?.space && formik.errors.span?.space}
            label={t('spaceInput')}
            name="span.space"
            onBlur={formik.handleBlur}
            
          />
        </div>
        <SupplementSpace type="span"/>
      </GreyInputsContainer>
      <InputsContainer>
        {formik.values.option === OPTION_P302_WITH_MODULE && (
          <>
            <div className="form-group">
              <InputNumber
                change={(v) => onPropertyChange(formik, 'span', 'moduleHeight', v)}
                val={formik.values.span.moduleHeight}
                info={formik.touched.span?.moduleHeight && formik.errors.span?.moduleHeight}
                label={t('moduleHeightInput')}
                name="span.moduleHeight"
                onBlur={formik.handleBlur}
                
              />
            </div>
          </>
        )}
        <div className="form-group">
          <InputNumber
            change={(v) => onPropertyChange(formik, 'span', 'amount', v)}
            val={formik.values.span.amount}
            info={formik.touched.span?.amount && formik.errors.span?.amount}
            label={t('spansAmountInput')}
            name="span.amount"
            onBlur={formik.handleBlur}
            
          />
        </div>
      </InputsContainer>
    </>
  );
};

export default BottomSpan;
