import React from 'react';
import styled from 'styled-components';

const Top = styled.div`
  background-image: url('img/singleLeafGate/top-middle.png');
  width: 21px;
  height: 20px;
  background-size: contain;
  background-repeat: no-repeat;
`;

const Middle = styled.div`
  background-image: url('img/singleLeafGate/middle.png');
  width: 21px;
  background-size: contain;
  height: calc(100% - 56px);
`;

const Bottom = styled.div`
  background-image: url('img/singleLeafGate/bottom-middle.png');
  width: 21px;
  height: 20px;
  background-size: cover;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100% + 16px);
`;

const CenterPart = () => (
  <Container>
    <Top />
    <Middle />
    <Bottom />
  </Container>
);

export default CenterPart;
