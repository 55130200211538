import React, {useState, useRef} from 'react';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faImage} from '@fortawesome/free-regular-svg-icons';
import {faTimes} from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';

import useOutsideClick from 'useOutsideClick';

import Slider from './Slider';
import { useTranslation } from 'react-i18next';

const VisualisationButton = () => {
  const [show, setShow] = useState(false);
  const ref = useRef();
  useOutsideClick(ref, () => {
    setShow(false)
  });
  const { t } = useTranslation();
  return (
    <div>
      {!show && <Button className="btn bn-link" type="button" onClick={() => setShow(true)} ref={ref}
                        data-sentry="showVisualisation"
      >
        <Icon icon={faImage}/>
        {t('automationPreview')}
      </Button>}
      {show && <Modal className="modal" tabIndex="-1" role="dialog">
        <div className="modal-dialog" role="document" ref={ref}>
          <Inner className="modal-content">
            <HeaderContainer>
              <Header>{t('automationReinforcement')}</Header>
              <Close
                type="button"
                className="btn"
                onClick={() => setShow(false)}>
                <FontAwesomeIcon icon={faTimes}/>
              </Close>
            </HeaderContainer>
            <Slider close={() => {
              setShow(false)
            }}/>
          </Inner>
        </div>
      </Modal>}
    </div>
  );
};

const Button = styled.button`
  display: flex;
  align-items: center;
  font-size: .9rem;
  padding: 0;
  margin-left: 2rem;
  margin-bottom: 1rem;
`;

const Icon = styled(FontAwesomeIcon)`
  font-size: 1.3rem;
  margin-right: .8rem;
`;

const Modal = styled.div`
  display: flex;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.9);

  .modal-dialog {
    max-width: 850px;
  }
`;

const Inner = styled.div`
  min-width: 800px;
  padding: 3rem 3rem 2rem 3rem;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
`;

const Header = styled.h1`
  font-weight: 400;
`;

const Close = styled.button`
  font-size: 1.5rem;
  padding: 0;
`;

export default VisualisationButton;
