import React, {useContext} from 'react';

import GateCommon from './GateCommon';
import ModuleData from '../ModuleData';
import Context from '../context';
import {fenceTypesHuman} from "../../App";
import { useTranslation } from 'react-i18next';
import SummaryPageGate from 'Pdf/SummaryPage/SummaryPageGate';

const GatePage = ({render}) => {
  const {values, gateAreasAmount: areasAmount} = useContext(Context);
  const {width, space, chosen, height, gateType, leftWingWidth, rightWingWidth} = values.gate;
  const { t } = useTranslation();
  if (!chosen) return null;

  return (
    <div className="new-page">
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="pdfHeader">
              <h2>{t(fenceTypesHuman[gateType])}</h2>
              <div className="headerLine"/>
            </div>
            <div className="fenceVisualisation">
              <img src={`${render}`} alt=""/>
            </div>
            <div className="fenceTypeData">
              <GateCommon/>
              {gateType === 'nonSymmetricalGate' &&
              <p>
                <b>{t('leftWingWidth')}:</b> {leftWingWidth} mm
              </p>}
              {gateType === 'nonSymmetricalGate' &&
              <p>
                <b>{t('rightWingWidth')}:</b> {rightWingWidth} mm
              </p>}
              {gateType !== 'nonSymmetricalGate' &&
              <p>
                <b>{t('width')}:</b> {width} mm
              </p>}
              <p>
                <b>{t('height')}:</b> {height} mm
              </p>
              <p>
                <b>{t('space')}:</b> {space} mm
              </p>
              {(gateType === 'gateSelfSupporting' || gateType === 'nonSymmetricalGate') &&
              <p>
                <b>{t('areasAmount')}:</b> {areasAmount} szt
              </p>}
              <ModuleData type="gate"/>
              <SummaryPageGate />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GatePage;
