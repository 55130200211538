import i18next from '../../Translation/config';
import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { useTranslation } from 'react-i18next';

const InfoGateway = () => {
  return <div className="platesWidthKey">
    {i18next.language === 'en' && (
    <p>
      The value entered in the "Gate Width" field results from the following conditions:
      <ul>
        <li>Gateway (standard hinges): Gate Width - 275</li>
        <li>Gateway (180-degree hinges): Gate Width - 225</li>
        <li>Gateway (hydraulic self-closer): Gate Width - 245</li>
      </ul>
      Gate Width - value entered in the "Width of gateway/gate" field
    </p>
    )}
    {i18next.language === 'pl' && (
    <p>
      Wartość podana w polu "Szerokość pola" wynika z poniższych warunków:
      <ul>
        <li>Furtka (zawiasy standardowe): Światło - 275</li>
        <li>Furtka (zawias 180 stopni): Światło - 225</li>
        <li>Furtka (samodomykacz hydrauliczny): Światło - 245</li>
      </ul>
      Światło - wartość wpisana w pole "Szerokość furtki/bramy"
    </p>
    )}
  </div>;
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };
  
  export default function InfoGatewayPopup() {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const { t } = useTranslation();
  
    return (
      <div>
        <Button 
            onClick={handleOpen}
            style={{ padding: "0.4rem 2rem", background:"#44AF69", color:"white", marginBottom: "10px", borderRadius: "25px" }}
        >{t('popupTitle')}
        </Button>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <InfoGateway />
          </Box>
        </Modal>
      </div>
    );
  }
