import {useFormikContext} from 'formik';
import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import {
  FENCE_TYPE_GATE,
  FENCE_TYPE_GATEWAY,
  FENCE_TYPE_SPAN,
} from 'App';
import Space from './Inc/Space';

const Container = styled.div`
  position: absolute;
  width: 50px;
  ${(props) => props.side === 'spaceSideLeft' && 'left: -15px;'}
  ${(props) => props.side === 'spaceSideRight' && 'right: -10px;'}
  ${(props) => props.visualisationPreparedForFile && 'display: none'}
`;

const SpaceFromOtherProjects = ({
 type, side,
}) => {
  const formik = useFormikContext();
  let height;

  // eslint-disable-next-line max-len
  const visualisationPreparedForFile = useSelector((state) => state.app.visualisationPreparedForFile);
  const scale = useSelector((state) => state.app.scale);
  const design = formik.values[type];
  const gateType = design === formik.values.gate ? formik.values.gate.gateType : null;

  const getSpacing = () => {
    const designSpacing = design.rodsStructure.map((struct) => (
      <div key={Math.random()}>
        <div
          style={{ height: `${struct.size / scale}px` }}
        />
        <Space
          space={struct.space}
          scale={scale}
          projectType={type}
          fenceType={gateType}
          spaceType={'spaceOtherProject'}
        />
      </div>
    ));

    if (type === FENCE_TYPE_SPAN) {
      height = 0;
    } else if (type === FENCE_TYPE_GATEWAY) {
      height = 20;
    } else if (type === FENCE_TYPE_GATE) {
      height = 20
    } else {
      throw new Error('Type not known.');
    }

    return (
      <Container
        style={{
          top: `${height}px`,
        }}
        side={side}
        visualisationPreparedForFile={visualisationPreparedForFile}
      >
        { designSpacing }
      </Container>
    );
  };

  return (
    getSpacing()
  );
};

export default SpaceFromOtherProjects;
