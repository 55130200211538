import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import {useFormikContext} from 'formik';
import { useTranslation } from 'react-i18next';

const IncludeButton = ({type}) => {
  const formik = useFormikContext();
  if (formik.values[type].chosen) return null;
  const add = () => {
    formik.setFieldValue(`${type}.chosen`, true)
  };
  const addGate = (type) => {
    formik.setFieldValue(`gate.chosen`, true)
    formik.setFieldValue(`gate.gateType`, type)
  };
  const { t } = useTranslation();
  if (type === 'gate') {
    return <Container>
      <Add type="button" className="btn btn-icon-left" onClick={() => addGate('gateTilting')}>
        <FontAwesomeIcon icon={faPlus} />
        {t('gateTiltingAdd')}
      </Add>
      <Add type="button" className="btn btn-icon-left" onClick={() => addGate('gateSelfSupporting')}>
        <FontAwesomeIcon icon={faPlus} />
        {t('gateSelfSupportingAdd')}
      </Add>
      <Add type="button" className="btn btn-icon-left" onClick={() => addGate('singleLeafGate')}>
        <FontAwesomeIcon icon={faPlus} />
        {t('singleLeafGateAdd')}
      </Add>
      <Add type="button" className="btn btn-icon-left" onClick={() => addGate('foldingGate')}>
        <FontAwesomeIcon icon={faPlus} />
        {t('foldingGateAdd')}
      </Add>
      <Add type="button" className="btn btn-icon-left" onClick={() => addGate('nonSymmetricalGate')}>
        <FontAwesomeIcon icon={faPlus} />
        {t('nonSymmetricalGateAdd')}
      </Add>
    </Container>
  }
  return (
    <Container>
      <Add type="button" className="btn btn-icon-left" onClick={add}>
        <FontAwesomeIcon icon={faPlus} />
        {t('addToProject')}
      </Add>
    </Container>
  );
};

const Container = styled.div`
  margin: 2rem 2rem 2rem 4rem;
`;

const Add = styled.button`
  color: #fff;
  background: #575B5D;
  margin-bottom: 1rem;
  &:hover {
    color: #fff;
    background: #424749;
  }
`;

export default IncludeButton;
