import {createContext} from 'react';

const Context = createContext({});

// returns amount of spaces if a space is more than 0 mm
export const calculateSpacesAmount = (rodsStructure) => {
    return rodsStructure.reduce(
        (prev, curr) => {
            if (curr.space) {
                prev += 1;
            }
            return prev;
        },
        0
    );
}

export default Context;
