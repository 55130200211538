import React from 'react';

import {OptionTitle, SmallerP} from 'GettingStarted/GettingStarted';
import { useTranslation } from 'react-i18next';

const P302Module = () => {
  const { t } = useTranslation();
  return (
    <div>
      <OptionTitle>{t('fenceP302')}{' '}{t('fenceP302module')}</OptionTitle>
    </div>
  );
};

export default P302Module;
