import {SMALL_PLATE_HEIGHT, STANDARD_PLATE_HEIGHT} from 'App';
import {HINGES_180, HINGES_LOCINOX_MAMMOTH, HINGES_STANDARD} from 'fenceTypesConstants';

export const calculateGatewayStandardPlates = ({elements}) => {
  return elements.filter((el) => el === STANDARD_PLATE_HEIGHT).length;
}

export const calculateGatewaySmallPlates = ({moduleHeight, elements}) => {
  return elements.filter((el) => el === SMALL_PLATE_HEIGHT && el !== moduleHeight).length;
}

export const calculateGatewayNonStandardPlates = ({moduleHeight, elements}) => {
  return elements.filter((el) => el !== STANDARD_PLATE_HEIGHT && el !== SMALL_PLATE_HEIGHT && el !== moduleHeight).length;
}

export const calculateGatewayRodsAmount = ({standardPlates, nonStandardPlates, smallPlates, amount}) => {
  return (standardPlates + nonStandardPlates + smallPlates) * 2 * amount;
}

export const calculateGatewayAreaWidth = ({width, hinges}) => {
  switch (hinges) {
    case HINGES_STANDARD:
      return width - 275;
    case HINGES_180:
      return width - 225;
    case HINGES_LOCINOX_MAMMOTH:
      return width - 245;
    default:
      throw new Error('42657141-f4f1-49ab-8802-eba619245a44: unsupported hinges');
  }
}
