import i18next from './Translation/config';

export const LAST_PLATE_INFO = i18next.language === 'pl' ? 'W projekcie znajduje sie płyta o wysokości niestandardowej. Płyty o niestandardowych wysokościach produkowane są jako płyty o wysokości 302 mm i wymagają samodzielnego docinania do pożądanej wysokości. Do każdej płyty niestandardowej dodawany jest dodatkowy ceownik. Ceownik należy samodzielnie zamontować do uprzednio dociętej płyty.' : 'The project includes a plate with a non-standard height. Plates with non-standard heights are produced as 302 mm high plate and require cutting to the desired height. An additional C-section bar is added to each custom plate. The C-section bar must be mounted to a previously cut plate.';

export const projectKeys = [
  'span',
  'gateway',
  'gate',
];

export const HINGES_STANDARD = 'STANDARD';
export const HINGES_180 = '180';
export const HINGES_LOCINOX_MAMMOTH = 'LOCINOX_MAMMOTH';

export const hinges = {
  [HINGES_STANDARD]: 'Zawiasy standardowe',
  [HINGES_180]: 'Zawias 180 stopni',
  [HINGES_LOCINOX_MAMMOTH]: 'Hydrauliczny samodomykacz',
};
