import React from 'react';
import styled from 'styled-components';
import logo from 'assets/logo.svg';
import i18n from 'i18next';

const Container = styled.div`
   padding: 4rem 0;
`;

const LogoImg = styled.img`
  width: 190px;
  height: auto;
`;

const changeLanguage = ((e) => {
  i18n.changeLanguage(e.target.value);
});

const Header = () => (
  <Container className="container-xxl">
    <div className="row">
      <div className="col">
        <div>
          <a href="/">
            <LogoImg src={logo} alt="logo" />
          </a>
        </div>
      </div>
      <div>
        <button
          style={{ backgroundColor: i18n.resolvedLanguage === 'pl' ? "red" : "gray" }}
          className="btn btn-danger"
          onClick={changeLanguage}
          value='pl'
        >PL
        </button>
        <button
          style={{ backgroundColor: i18n.resolvedLanguage === 'en' ? "red" : "gray" }}
          className="btn btn-danger"
          onClick={changeLanguage}
          value='en'
        >EN
        </button>
      </div>
    </div>
  </Container>
);

export default Header;
