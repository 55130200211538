import {useFormikContext} from 'formik';
import React, { useState } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';

import { forceRenderDesign } from 'Design/Design';

import FENCE_TYPE_SPAN from 'App';
import { useTranslation } from 'react-i18next';

const CopyBtn = styled.button`
  width: 230px;
  padding: 0.25rem 2rem;
  border-radius: 22px;
  margin-bottom: 1rem;
`;

const DropdownBtn = styled.button`
  width: 230px;
  padding: 0.25rem 2rem;
  border-radius: 22px;
  margin-bottom: 0.5rem;
`;

const List = styled.ul`
  list-style-type: none;
  position: absolute;
  margin-top: -0.5rem;
  margin-left: -2.5rem;
  z-index: 999;
  background: #ffffff;
  padding-right: 12%;
`;

const CopyValuesBtn = ({ type }) => {
  const formik = useFormikContext();
  const { t } = useTranslation();
  const [showOptions, setShowOptions] = useState(false);
  const copyValues = ({width, height, space, moduleHeight, moduleColor, hinges}) => {
    let v;
    if (type !== FENCE_TYPE_SPAN && type.gateType !== "gateSelfSupporting") {
     v = {
        ...formik.values[type],
        width,
        height,
        space,
        moduleHeight,
        moduleColor,
        hinges,
      };
    } else {
      v = {
        ...formik.values[type],
        width,
        height,
        space,
        moduleHeight,
        moduleColor,
      };
    }
    formik.setFieldValue(type, v);
    const values = {
      ...formik.values,
      [type]: v,
    }
    forceRenderDesign(formik, values, type)
    setShowOptions(false);
  };

  const filledInTypes = [];
  if (formik.values.span.renderDesign && type !== 'span') {
    filledInTypes.push(formik.values.span);
  }
  if (formik.values.gateway.renderDesign && type !== 'gateway') {
    filledInTypes.push(formik.values.gateway);
  }
  if (formik.values.gate.renderDesign && type !== 'gate') {
    filledInTypes.push(formik.values.gate);
  }
  const Options = () => filledInTypes.map((design) => {
    let label;

    if (design === formik.values.span) {
      label = t('fromSpan');
    } else if (design === formik.values.gateway) {
      label = t('fromGateway');
    } else {
      label = t('fromGate');
    }

    return (
      <li key={Math.random()}>
        <DropdownBtn
          className="btn btn-secondary"
          onClick={() => copyValues(design)}
          type="button"
        >
          <FontAwesomeIcon icon={faPlus} style={{ marginRight: '1rem' }} />
          {label}
        </DropdownBtn>
      </li>
    );
  });
  const toggle = () => {
    setShowOptions(p => !p);
    const tenSeconds = 10000;
    setTimeout(() => {
      setShowOptions(false);
    }, tenSeconds);
  };

  if (filledInTypes.length >= 1) {
    return (
      <div>
        <div>
          <CopyBtn
            className="btn btn-secondary"
            onClick={toggle}
            type="button"
            showOptions={showOptions === true}
            data-sentry="copy"
          >
            {showOptions && <FontAwesomeIcon icon={faTimes} style={{ marginRight: '1rem' }}/>}
            {!showOptions && <FontAwesomeIcon icon={faPlus} style={{ marginRight: '1rem' }}/>}
            <span>{showOptions ? t('cancel') : t('copy')}</span>
          </CopyBtn>
        </div>
        {showOptions && <div style={{ display: 'flex' }}>
          <List>
            <Options/>
          </List>
        </div>}
      </div>
    );
  }
  return null;
};

export default CopyValuesBtn;
