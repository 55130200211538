import React from 'react';
import {useFormikContext} from 'formik';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheck} from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';


import {OPTION_P302, OPTION_P302_WITH_MODULE} from 'App';
import {
  StartGoBackBtnContainer,
  StartPageHeaderContainer,
  StartPageHeaderTopRow,
  StartTopRowLeft,
} from 'TermsModule';

import PreviewProjectButton from './PreviewProjectButton';
import TopSpan from './Top/TopSpan';
import TopGateway from './Top/TopGateway';
import TopGate from './Top/TopGate/TopGate';
import Visualisations from './Center/Visualisations';
import BottomSpan from './Bottom/BottomSpan';
import BottomGateway from './Bottom/BottomGateway';
import BottomGate from './Bottom/BottomGate';
import PreviewPDFButton from './PreviewPDFButton';

const Project = () => {
  const dispatch = useDispatch();
  const formik = useFormikContext();
  const { t } = useTranslation();

  if (!formik.values.termsAccepted) return null;
  return (
    <Container className="container-xxl">
      <div className="row">
        <div className="col">
          <StartPageHeaderContainer>
            <div>
              <div>
                <h5>{t('design')}</h5>
              </div>
            </div>
            <StartPageHeaderTopRow>
              <StartTopRowLeft>
                {formik.values.option === OPTION_P302 && <ProjectTitle>{t('fenceP302')}</ProjectTitle>}
                {formik.values.option === OPTION_P302_WITH_MODULE && <ProjectTitle>{t('fenceP302module')}</ProjectTitle>}
                <StartGoBackBtnContainer>
                  <ChangeModelBtn className="btn btn-danger" href="/">
                    {t('modelChange')}
                  </ChangeModelBtn>
                </StartGoBackBtnContainer>
              </StartTopRowLeft>
              <div>
                <PreviewProjectButton/>
                <PreviewPDFButton values={formik.values}/>
                <FinishBtn
                  type="button"
                  className="btn btn-success"
                  onClick={() => dispatch({type: 'FINISH_PROJECT'})}
                >
                  <FontAwesomeIcon
                    icon={faCheck}
                    style={{color: '#ffffff', marginRight: '1rem'}}
                  />
                  {t('projectFinish')}
                </FinishBtn>
              </div>
            </StartPageHeaderTopRow>
          </StartPageHeaderContainer>
          <CenterContainer>
            <ColContainer>
              <TopSpan/>
            </ColContainer>
            <ColContainer>
              <TopGateway/>
            </ColContainer>
            <ColContainer>
              <TopGate/>
            </ColContainer>
          </CenterContainer>
          <Row>
            <Visualisations/>
          </Row>
          <Row>
            <ColContainer>
              <BottomSpan/>
            </ColContainer>
            <ColContainer>
              <BottomGateway/>
            </ColContainer>
            <ColContainer>
              <BottomGate/>
            </ColContainer>
          </Row>
        </div>
      </div>
    </Container>
  );
};

const CenterContainer = styled.div`
  margin-top: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: inherit;
`;

const ColContainer = styled.div`
  background: #ffffff;
  width: 32%;
  position: relative;
`;

const Container = styled.main`
  padding: 2rem 0;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: inherit;
`;

const ProjectTitle = styled.h1`
  font-weight: bold;
`;

const FinishBtn = styled.button`
  margin-left: 1rem;
  border-radius: 25px;
  padding: 0.4rem 2rem;
  background: #44AF69;
`;

const ChangeModelBtn = styled.a`
  padding: .4rem 2rem;
`;

export const InputsContainer = styled.div`
  padding: 2rem 10rem 0 4rem;
  display: flex;
  flex-direction: column;
`;

export const GreyInputsContainer = styled.div`
  background: #e6e6e6;
  padding: 1rem 0 1rem 4rem;
  min-height: ${props => props.automation ? '320' : '220'}px;

  & > div {
    padding-right: 10rem;
  }
`;

export const HingesSelect = styled.select`
  background: #626567;
  color: #ffffff;
  border-radius: 30px;
  height: 35px;
  padding-left: 1rem;

  &:focus {
    outline: none;
    border: none;
    box-shadow: none;
    background: #626567;
    color: #ffffff;
  }
`;

export const Label = styled.label`
  font-size: .8rem;
`;

export default Project;
