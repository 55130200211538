import React, {useContext} from 'react';

import {OPTION_P302} from 'App';
import { useTranslation } from 'react-i18next';
import Context from './context';

const ModuleData = ({type}) => {
  const {values, gateModulesAmount: modulesAmount} = useContext(Context);
  const { t } = useTranslation();
  if (values.option === OPTION_P302) return null;
  return <>
    <p>
      <b>{t('moduleHeight')}:</b> {values[type].moduleHeight} mm
    </p>
    <p>
      <b>{t('moduleColor')}:</b> {values.moduleColor}
    </p>
    {!!modulesAmount && <p>
      <b>{t('modulesAmount')}:</b> {modulesAmount} szt
    </p>}
  </>
}

export default ModuleData;
