import React, {useContext} from 'react';

import Context, { calculateSpacesAmount } from '../context';
import {calculateGateAreaWidth} from '../Gate/gateFunctions';
import ModuleData from '../ModuleData';
import { useTranslation } from 'react-i18next';

const SummaryPageGate = () => {
  const {
    values,
    gateRodsAmount: rodsAmount,
    gateStandardPlates: standardPlates,
    gateSmallPlates: smallPlates,
    gateNonStandardPlates: nonStandardPlates,
  } = useContext(Context);
  const {chosen, gateType, width, hinges, mountingStrip, rodsStructure , leftWingWidth, rightWingWidth} = values.gate;
  if (!chosen) return null;
  const areaWidth = calculateGateAreaWidth({width, hinges, type: gateType, mountingStrip, leftWingWidth, rightWingWidth});
  const totalPlates = standardPlates + smallPlates + nonStandardPlates;
  const spacesAmount = calculateSpacesAmount(rodsStructure);
  const showSection = ['singleLeafGate', 'foldingGate', 'nonSymmetricalGate'].includes(gateType);
  const { t } = useTranslation();
  return (
    <div className="sumUpData">
      {showSection && (
        <p>
          <b>{t('totalPlatesAmount')}:</b> {totalPlates} szt
        </p>
      )}
      {showSection && (
        <p>
          <b>{t('cSectionsTopBottomAmount')}:</b> {rodsAmount} szt
        </p>
      )}
      <p>
        <b>{t('spaceHigherThan0Amount')}:</b> {spacesAmount} szt
      </p>
      <p>
        <b>{t('areaWidth')}:</b> {areaWidth} mm
      </p>
      {showSection && (
        <p>
          <b>{t('platesPerGate')}:</b> {totalPlates} szt
        </p>
      )}
      <ModuleData type="gate"/>
    </div>
  );
};

export default SummaryPageGate;
