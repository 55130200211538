import React, {useState} from 'react';
import styled from 'styled-components';
import {useDispatch} from 'react-redux';
import domtoimage from 'dom-to-image';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSearch,faSpinner} from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

const Button = styled.button`
  background: #0165c7;
  padding: .4rem 2rem;
  color: #fff;
  &:hover {
    color: #ffff;
  }
`;

const PreviewProjectButton = ({className}) => {
  const dispatch = useDispatch();
  const [processing, setProcessing] = useState(false);
  const getVisualisations = () => {
    setProcessing(true);
    dispatch({type: 'VISUALISATION_PREPARED_FOR_FILE', visualisationPreparedForFile: true});
    domtoimage.toJpeg(document.getElementById('allVisualisations'), {quality: 0.95, bgcolor: '#ffffff'})
      .then((dataUrl) => {
        const link = document.createElement('a');
        link.download = 'p302-wizualizacja-projekt.jpeg';
        link.href = dataUrl;
        link.click();
        dispatch({type: 'VISUALISATION_PREPARED_FOR_FILE', visualisationPreparedForFile: false});
      });
    setProcessing(false);
  };
  const { t } = useTranslation();

  return (
    <Button
      type="button"
      className={`${className} btn`}
      onClick={getVisualisations}
    >
      {processing && <FontAwesomeIcon className="spinner" icon={faSpinner}/>}
      {!processing && <FontAwesomeIcon icon={faSearch} style={{marginRight: '1rem'}}/>}
      {t('projectView')}
    </Button>
  );
};

export default PreviewProjectButton;
