import React from 'react';
import styled from 'styled-components';

const Top = styled.div`
  background-image: url('img/gateTilting/top-middle.png');
  width: 42px;
  height: 20px;
  background-size: contain;
  background-repeat: no-repeat;
`;

const Middle = styled.div`
  background-image: url('img/gateTilting/middle.png');
  width: 42px;
  background-size: contain;
  height: calc(100% - 56px);
`;

const Bottom = styled.div`
  background-image: url('img/gateTilting/bottom-middle.png');
  width: 42px;
  height: 20px;
  background-size: cover;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  height: 100%;
  margin-left: auto;
  margin-right: auto;
`;

const CenterPart = () => (
  <Container>
    <Top />
    <Middle />
    <Bottom />
  </Container>
);

export default CenterPart;
