import React from 'react';

import GatePage from './Gate/GatePage';
import {
  calculateGateAreasAmount,
  calculateGateRodsAmount,
  calculateGateStandardPlates,
  calculateGateSmallPlates,
  calculateGateNonStandardPlates
} from './Gate/gateFunctions';

import GatewayPage from './Gateway/GatewayPage';
import {calculateGatewayNonStandardPlates, calculateGatewayStandardPlates, calculateGatewaySmallPlates} from './Gateway/gatewayFunctions';

import SpanPage from './Span/SpanPage';
import {calculateSpanStandardPlates, calculateSpanNonStandardPlates} from './Span/spanFunctions';

import SummaryPage from './SummaryPage/SummaryPage';

import Context from './context';

const Pdf = ({
               values,

               spanElements,
               spanRender,

               gatewayElements,
               gatewayRender,

               gateElements,
               gateRender,
             }) => {
  const spanNonStandardPlates = calculateSpanNonStandardPlates({elements: spanElements, moduleHeight: values.span.moduleHeight});
  const spanStandardPlates = calculateSpanStandardPlates({elements: spanElements});

  const gatewayStandardPlates = calculateGatewayStandardPlates({elements: gatewayElements});
  const gatewaySmallPlates = calculateGatewaySmallPlates({moduleHeight: values.gateway.moduleHeight, elements: gatewayElements});
  const gatewayNonStandardPlates = calculateGatewayNonStandardPlates({moduleHeight: values.gateway.moduleHeight, elements: gatewayElements});
  const gatewayTotalStandardPlates = gatewayStandardPlates * values.gateway.amount;
  const gatewayTotalPlates = (gatewayStandardPlates + gatewaySmallPlates + gatewayNonStandardPlates) * values.gateway.amount;

  const gateAreasAmount = values.gate.chosen ? calculateGateAreasAmount({type: values.gate.gateType, width: values.gate.width, leftWingWidth: values.gate.leftWingWidth, rightWingWidth: values.gate.rightWingWidth}) : null;
  const gateStandardPlates = calculateGateStandardPlates({values, elements: gateElements, areasAmount: gateAreasAmount});
  const gateSmallPlates = calculateGateSmallPlates({values, elements: gateElements, areasAmount: gateAreasAmount});
  const gateNonStandardPlates = calculateGateNonStandardPlates({
    values,
    elements: gateElements,
    areasAmount: gateAreasAmount,
    moduleHeight: values.gate.moduleHeight,
  });
  const gateRodsAmount = calculateGateRodsAmount({
    standardPlates: gateStandardPlates,
    nonStandardPlates: gateNonStandardPlates,
    smallPlates: gateSmallPlates,
    amount: values.gate.amount,
  });
  const gateModulesAmount = gateAreasAmount * values.gate.amount;

  return (
    <Context.Provider value={{
      values,

      spanNonStandardPlates,
      spanStandardPlates,
      spanElements,

      gatewayElements,
      gatewayStandardPlates,
      gatewaySmallPlates,
      gatewayNonStandardPlates,
      gatewayTotalStandardPlates,
      gatewayTotalPlates,

      gateElements,
      gateRodsAmount,
      gateStandardPlates,
      gateSmallPlates,
      gateNonStandardPlates,
      gateAreasAmount,
      gateModulesAmount,
    }}>
      <SpanPage render={spanRender}/>
      <GatewayPage render={gatewayRender}/>
      <GatePage render={gateRender}/>
    </Context.Provider>
  );
};

export default Pdf;
