import React, {useContext} from 'react';

import ModuleData from '../ModuleData';
import Context from '../context';

import {calculateSpanRodsAmount} from './spanFunctions';
import SpanCommon from './SpanCommon';
import { useTranslation } from 'react-i18next';
import SummaryPageSpan from 'Pdf/SummaryPage/SummaryPageSpan';

const SpanPage = ({ render }) => {
  const {
    values,
    spanNonStandardPlates: nonStandardPlates,
    spanStandardPlates: standardPlates,
  } = useContext(Context);
  if (!values.span.chosen) return null;
  const {amount, height, space} = values.span;
  const rodsAmount = calculateSpanRodsAmount({standardPlates, nonStandardPlates, amount});
  const { t } = useTranslation();

  return (
    <div className="new-page">
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="projectName">
              <h1>{t('projectName')}: {values.projectName}</h1>
            </div>
            <div className="pdfHeader">
              <h2>{t('span')}</h2>
              <div className="headerLine"/>
            </div>
            <div className="fenceVisualisation">
              <img src={`${render}`} alt=""/>
            </div>
            <div className="fenceTypeData">
              <SpanCommon/>
              <p>
                <b>{t('height')}:</b> {height} mm
              </p>
              <p>
                <b>{t('space')}:</b> {space} mm
              </p>
              <p><b>{t('XPSPlateColor')}:</b> {values.color}</p>
              <ModuleData type="span"/>
              <SummaryPageSpan />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SpanPage;
