import React from 'react';
import styled from 'styled-components';
import {useFormikContext} from 'formik';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronRight} from '@fortawesome/free-solid-svg-icons';
import { useTranslation, Trans } from 'react-i18next';

import {OPTION_P302_WITH_MODULE, SUPPLEMENT_SPACE_PLATE_MIN_HEIGHT} from 'App';
import InfoGatewayPopup from 'Pdf/SummaryPage/InfoGateway';
import InfoGateTiltingPopup from 'Pdf/SummaryPage/InfoGateTilting';
import InfoGatePopup from 'Pdf/SummaryPage/InfoGate';

export const StartContainer = styled.div`
  padding: 2rem 0;
`;

export const StartPageHeaderContainer = styled.div`
  color: #ffffff;
`;

export const StartPageHeaderTopRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const StartTopRowLeft = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const StartGoBackBtnContainer = styled.div`
  margin-left: 3rem;
`;

export const StartButton = styled.a`
  padding: .4rem 2rem;
`;

export const StartCenterContainer = styled.div`
  margin-top: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
`;

export const StartTypeContainer = styled.div`
  background: #ffffff;
  padding: 2rem 4rem;
  width: calc(33.333% - 1.5rem);
`;

export const StartConditionsContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const StartTypeTitle = styled.h4`
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 2rem;
`;

export const StartImageContainer = styled.div`
  display: flex;
`;

export const StartGatewayContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const StartRulesContainer = styled.ul`
  margin-right: auto;
  min-height: 500px;
  overflow: auto;
  margin-left: -1rem;
`;

export const StartListItem = styled.li`
  color: #ff0000;
`;

export const StartListParagraph = styled.p`
  text-align: justify;
  color: #000000;
`;

const GatewayImg = styled.img`
  height: 160px;
  width: auto;
`;

const GateTiltingImg = styled.img`
  height: 160px;
  width: auto;
`;

const GateSelfSupportingImg = styled.img`
  height: 160px;
  width: auto;
`;

const GatewayConditions = () => (
  <>
    <StartTypeTitle><Trans>gateway</Trans></StartTypeTitle>
    <StartConditionsContainer style={{flexDirection: 'column'}}>
      <StartRulesContainer>
        <StartListItem>
          <StartListParagraph>
            <b><Trans>firstPlate</Trans></b>
            <Trans>firstPlateGatewayDefault</Trans>
          </StartListParagraph>
        </StartListItem>
        <StartListItem>
          <StartListParagraph>
            <b><Trans>spaceBetweenLastPlateAndBottom</Trans></b>
            <Trans i18nKey='spaceBetweenLastPlateAndBottomCondition'>{{SUPPLEMENT_SPACE_PLATE_MIN_HEIGHT}}</Trans>
          </StartListParagraph>
        </StartListItem>
        <StartListItem>
          <StartListParagraph>
            <Trans>standardWidthDimensions</Trans>
            <b> <Trans>gatewayLockAndHinge</Trans></b>
          </StartListParagraph>
        </StartListItem>
        <StartListItem>
          <StartListParagraph>
            <b><Trans>module</Trans></b>
            <Trans>moduleCondition</Trans>
          </StartListParagraph>
        </StartListItem>
      </StartRulesContainer>
      <InfoGatewayPopup />
      <StartGatewayContainer>
        <GatewayImg src="img/conditions/moduleGateway.png" alt=""/>
      </StartGatewayContainer>
    </StartConditionsContainer>
  </>
);

const GateTiltingConditions = () => (
  <>
    <StartTypeTitle><Trans>gateTilting</Trans></StartTypeTitle>
    <StartConditionsContainer style={{flexDirection: 'column'}}>
      <StartRulesContainer>
        <StartListItem>
          <StartListParagraph>
            <b><Trans>firstPlate</Trans></b>
            <Trans>firstPlateTiltingDefault</Trans>
          </StartListParagraph>
        </StartListItem>
        <StartListItem>
          <StartListParagraph>
            <b><Trans>spaceBetweenLastPlateAndBottom</Trans></b>
            <Trans i18nKey='spaceBetweenLastPlateAndBottomCondition'>{{SUPPLEMENT_SPACE_PLATE_MIN_HEIGHT}}</Trans>
          </StartListParagraph>
        </StartListItem>
        <StartListItem>
          <StartListParagraph>
            <Trans>standardWidthDimensions</Trans>
            <b> <Trans>gateClamsAndLeavesGap</Trans></b>
          </StartListParagraph>
        </StartListItem>
        <StartListItem>
          <StartListParagraph>
            <b><Trans>module</Trans></b>
            <Trans>moduleCondition</Trans>
          </StartListParagraph>
        </StartListItem>
      </StartRulesContainer>
      <InfoGateTiltingPopup />
      <StartImageContainer>
        <GateTiltingImg src="img/conditions/moduleGateTilting.png" alt=""/>
      </StartImageContainer>
    </StartConditionsContainer>
  </>
);

const GateSelfSupportingConditions = () => (
  <>
    <StartTypeTitle><Trans>gateSelfSupporting</Trans></StartTypeTitle>
    <StartConditionsContainer style={{flexDirection: 'column'}}>
      <StartRulesContainer>
        <StartListItem>
          <StartListParagraph>
            <b><Trans>firstPlate</Trans></b>
            <Trans>firstPlateSelfSupDefault</Trans>
          </StartListParagraph>
        </StartListItem>
        <StartListItem>
          <StartListParagraph>
            <b><Trans>spaceBetweenLastPlateAndBottom</Trans></b>
            <Trans i18nKey='spaceBetweenLastPlateAndBottomCondition'>{{SUPPLEMENT_SPACE_PLATE_MIN_HEIGHT}}</Trans>
          </StartListParagraph>
        </StartListItem>
        <StartListItem>
          <StartListParagraph>
            <Trans>selfSupportingGateCondition</Trans>
            <b> <Trans>selfSuportingGateConditionRail</Trans></b>
          </StartListParagraph>
        </StartListItem>
        <StartListItem>
          <StartListParagraph>
            <b><Trans>selfSuportingGateConditionRailHeight</Trans></b>
            <Trans>selfSuportingGateConditionRailHeightIs</Trans>
          </StartListParagraph>
        </StartListItem>
        <StartListItem>
          <StartListParagraph>
            <b><Trans>module</Trans></b>
            <Trans>moduleCondition</Trans>
          </StartListParagraph>
        </StartListItem>
      </StartRulesContainer>
      <InfoGatePopup />
      <StartImageContainer>
        <GateSelfSupportingImg src="img/conditions/moduleGateSelfSupporting.png" alt=""/>
      </StartImageContainer>
    </StartConditionsContainer>
  </>
);

const TermsModule = () => {
  const formik = useFormikContext();
  const { t } = useTranslation();

  if (formik.values.termsAccepted || formik.values.option !== OPTION_P302_WITH_MODULE ||
    !formik.values.color || !formik.values.moduleColor) return null;
  return (
    <StartContainer className="container-xxl">
      <div className="row">
        <div className="col">
          <StartPageHeaderContainer>
            <div>
              <h5>{t("projectGuidelines")}</h5>
            </div>
            <StartPageHeaderTopRow>
              <StartTopRowLeft>
                <h1><b>{t("fenceP302")}</b></h1>
                <StartGoBackBtnContainer>
                  <StartButton className="btn btn-secondary" href="/">
                    {t("modelChange")}
                  </StartButton>
                </StartGoBackBtnContainer>
              </StartTopRowLeft>
              <div>
                <StartButton
                  type="button"
                  className="btn btn-success"
                  onClick={() => formik.setFieldValue('termsAccepted', true)}
                >
                  {t('guidelinesAccept')}
                  <FontAwesomeIcon
                    icon={faChevronRight}
                    style={{color: '#ffffff', marginLeft: '1rem'}}
                  />
                </StartButton>
              </div>
            </StartPageHeaderTopRow>
          </StartPageHeaderContainer>
          <StartCenterContainer>
            <StartTypeContainer>
              <GatewayConditions/>
            </StartTypeContainer>
            <StartTypeContainer>
              <GateTiltingConditions/>
            </StartTypeContainer>
            <StartTypeContainer>
              <GateSelfSupportingConditions/>
            </StartTypeContainer>
          </StartCenterContainer>
        </div>
      </div>
    </StartContainer>
  );
};

export default TermsModule;
