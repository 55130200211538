import React from 'react';

import {OptionTitle, SmallerP} from 'GettingStarted/GettingStarted';
import { useTranslation } from 'react-i18next';

const P302 = () => {
  const { t } = useTranslation();
  return <div>
    <OptionTitle>{t('fenceP302standard')}{' '}{t('fenceP302')}</OptionTitle>
  </div>
}

export default P302;
