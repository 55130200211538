import React, {useContext} from 'react';

import {calculateGatewayAreaWidth} from '../Gateway/gatewayFunctions';
import Context, { calculateSpacesAmount } from '../context';
import { useTranslation } from 'react-i18next';

const SummaryPageGateway = () => {
  const {
    values,
    gatewayTotalPlates: totalPlates,
  } = useContext(Context);
  const {chosen, hinges, width, rodsStructure} = values.gateway;
  if (!chosen) return null;
  const areaWidth = calculateGatewayAreaWidth({width, hinges});
  const spacesAmount = calculateSpacesAmount(rodsStructure);
  const { t } = useTranslation();
  return (
  <div className="sumUpData">
    <p>
      <b>{t('totalPlatesAmount')}:</b> {totalPlates} szt
    </p>
    <p>
      <b>{t('spaceHigherThan0Amount')}:</b> {spacesAmount} szt
    </p>
    <p>
      <b>{t('areaWidth')}:</b> {areaWidth} mm
    </p>
  </div>
  );
};

export default SummaryPageGateway;
