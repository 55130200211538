import React from 'react';
import styled from 'styled-components';

const Top = styled.div`
  background-image: url('img/gateTilting/top-right.png');
  width: 52px;
  height: 20px;
  background-size: contain;
  background-repeat: no-repeat;
  margin-bottom: -2px;
`;

const Pole = styled.div`
  background-size: contain;
  width: 52px;
  background-image: url('img/gateTilting/right.png');
  height: calc(100% - 35px);
  position: relative;
`;

const Bottom = styled.div`
  background-image: url('img/gateTilting/bottom-right.png');
  width: 52px;
  height: 33px;
  background-size: cover;
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100% + 16px);
`;

const RightPole = () => (
  <Container>
    <Top />
    <Pole />
    <Bottom />
  </Container>
);

export default RightPole;
