import React from 'react';
import styled from 'styled-components';

const Container = styled.footer`
  margin-top: 6rem;
  color: #76797a;
  font-size: 0.8125rem;
`;

const Copyright = styled.p`
  color: #76797a;
`;

const Details = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
  text-align: right;
`;

const Contact = styled.p`
  span:not(:last-of-type) {
    margin-right: 1rem;
  }
`;

const Footer = () => (
  <Container className="container-xxl">
    <div className="row">
      <div className="col-sm-2">
        <Copyright>&copy; Konsport</Copyright>
      </div>
      <div className="col-sm-4" />
      <Details className="col-sm-6">
        <Contact><span>Jana Kilińskiego 75</span><span>95-083 Kazimierz</span><span>+48 43 677 50 63</span><span>biuro@konsport.com.pl</span></Contact>
      </Details>
    </div>
  </Container>
);

export default Footer;
