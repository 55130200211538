import React from 'react';
import styled from 'styled-components';

import Automation from '../../Automation';
import Rods from '../../Rods';

const TopBar = styled.div`
  background-size: contain;
  background-repeat: repeat;
  height: 20px;
  width: 100%;
  background-image: url('img/gateSelfSupporting/top.png');
  z-index: 999;
`;

const BottomBar = styled.div`
  background-size: contain;
  margin-top: -4.5px;
  height: 42px;
  width: 100%;
  z-index: 9;
  background-image: url('img/gateSelfSupporting/bottom.png');
`;

const Container = styled.div`
  width: 75%;
  margin-left: -13px;
`;

const Body = ({ design, scale, projectType }) => (
  <Container>
    <TopBar />
    <Rods
      style={{ width: 'calc(100% + 30px)' }}
      design={design}
      scale={scale}
      projectType={projectType}
      fenceType="gateSelfSupporting"
    />
    <BottomBar />
    <Automation/>
  </Container>
);

export default Body;
