import domtoimage from 'dom-to-image';

import { SUPPLEMENT_SPACE_PLATE_MIN_HEIGHT } from 'App';

export const humanisePlateWord = (no) => {
  if (no <= 1) {
    return 'płyta';
  } else if (no > 1 && no <= 4) {
    return 'płyty';
  } else {
    return 'płyt';
  }
}

const createPdfVariables = (values) => {
  let p1;
  let p2;
  let p3;
  const promises = [];
  if (values.span.renderDesign) {
    p1 = domtoimage.toJpeg(document.getElementById('spanRender'), {quality: 0.95, bgcolor: '#ffffff'});
    promises.push(p1);
  } else {
    promises.push(null);
  }
  if (values.gateway.renderDesign) {
    p2 = domtoimage.toJpeg(document.getElementById('gatewayRender'), {quality: 0.95, bgcolor: '#ffffff'});
    promises.push(p2);
  } else {
    promises.push(null);
  }
  if (values.gate.renderDesign) {
    p3 = domtoimage.toJpeg(document.getElementById('gateRender'), {quality: 0.95, bgcolor: '#ffffff'});
    promises.push(p3);
  } else {
    promises.push(null);
  }

  return Promise.all(promises).then((renders) => {
    const spanElements = values.span.rodsStructure.map((object) => object.size);
    if (values.span.supplementSpace >= SUPPLEMENT_SPACE_PLATE_MIN_HEIGHT) {
      spanElements.push(values.span.supplementSpace);
    }

    const gatewayElements = values.gateway.rodsStructure.map((object) => object.size);
    if (values.gateway.supplementSpace >= SUPPLEMENT_SPACE_PLATE_MIN_HEIGHT) {
      gatewayElements.push(values.gateway.supplementSpace);
    }

    const gateElements = values.gate.rodsStructure.map((object) => object.size);
    if (values.gate.supplementSpace >= SUPPLEMENT_SPACE_PLATE_MIN_HEIGHT) {
      gateElements.push(values.gate.supplementSpace);
    }

    return {
      spanRender: renders[0] || null,
      gatewayRender: renders[1] || null,
      gateRender: renders[2] || null,
      spanElements,
      gatewayElements,
      gateElements,
    };
  });
};

export default createPdfVariables;
