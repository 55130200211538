import React, {useContext} from 'react';

import {humanisePlateWord} from '../commonFunctions';
import NonStandard from '../NonStandard';
import Context from '../context';

import {calculateGatewayRodsAmount} from './gatewayFunctions';
import { useTranslation } from 'react-i18next';

const GatewayCommon = () => {
  const {
    values,
    gatewayStandardPlates: standardPlates,
    gatewaySmallPlates: smallPlates,
    gatewayNonStandardPlates: nonStandardPlates,
    gatewayTotalStandardPlates: totalStandardPlates,
  } = useContext(Context);
  const {amount, chosen} = values.gateway;
  if (!chosen) return null;
  const totalSmallPlates = smallPlates * amount;
  const rodsAmount = calculateGatewayRodsAmount({
    standardPlates,
    nonStandardPlates,
    smallPlates,
    amount,
  });
  const totalNonStandardPlatesAmount = nonStandardPlates * amount;
  const { t } = useTranslation();

  return (
    <>
      <p>
        <b>{t('total302PlateAmount')}:</b> {totalStandardPlates} szt
      </p>
      <p>
        <b>{t('totalTop222PlateAmount')}:</b> {totalSmallPlates} szt
      </p>
      <NonStandard nonStandardPlates={totalNonStandardPlatesAmount}/>
    </>
  );
};

export default GatewayCommon;
