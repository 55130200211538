import React from 'react';

import {LAST_PLATE_INFO} from 'fenceTypesConstants';
import { useTranslation } from 'react-i18next';

const NonStandard = ({nonStandardPlates, type}) => {
  const { t } = useTranslation();
  if (!nonStandardPlates) return <p>
    <b>{t('nonStandardPlates')}:</b> {t('nd')}<br/>
  </p>;
  if (nonStandardPlates) return <p>
    <b>{t('nonStandardPlates')}:</b> {nonStandardPlates} szt<br/>
    {(type && type === 'span') && LAST_PLATE_INFO}
  </p>
  return null;
};

export default NonStandard;
