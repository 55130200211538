import React, {useContext} from 'react';

import NonStandard from '../NonStandard';
import {humanisePlateWord} from '../commonFunctions';
import Context from '../context';
import { useTranslation } from 'react-i18next';

const SpanCommon = () => {
  const {
    values,
    spanNonStandardPlates: nonStandardPlates,
    spanStandardPlates: standardPlates,
  } = useContext(Context);
  const {amount, width, chosen} = values.span;
  if (!chosen) return null;
  const totalStandardPlatesAmount = standardPlates * amount;
  const totalNonStandardPlatesAmount = nonStandardPlates * amount;
  const { t } = useTranslation();

  return (
    <>
      <p>
        <b>{t('width')}:</b> {width} mm
      </p>
      <p>
        <b>{t('total302PlateAmount')}:</b> {totalStandardPlatesAmount} szt
      </p>
      <NonStandard nonStandardPlates={totalNonStandardPlatesAmount} type="span"/>
    </>
  );
};

export default SpanCommon;
