import React from 'react';
import styled from 'styled-components';

import SelectGate from 'Project/SelectGate';
import ExcludeButton from 'Project/ExcludeButton';
import IncludeButton from 'Project/TypeTitle/IncludeButton';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
`;

const Title = styled.h2`
  padding: 2rem 0 0 4rem;
  margin: 0;
  font-size: 2rem;
  font-weight: bold;
`;

const TypeTitle = ({type}) => {
  const { t } = useTranslation();
  return (
    <Container>
      <TitleContainer>
        <Title>{t(type)}</Title>
        {type !== 'gate' && <ExcludeButton type={type}/>}
        <SelectGate type={type}/>
      </TitleContainer>
      <IncludeButton type={type}/>
    </Container>
  );
};

export default TypeTitle;
