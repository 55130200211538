import {combineReducers} from 'redux';

const appInitialState = {
  scale: 4,
  visualisationPreparedForFile: false,
  finishingProject: false,
  message: '',
  error: false,
};

const app = (state = appInitialState, action) => {
  switch (action.type) {
    case 'VISUALISATION_PREPARED_FOR_FILE':
      return {...state, visualisationPreparedForFile: action.visualisationPreparedForFile};
    case 'SAVING_PROJECT':
      return {
        ...state,
        visualisationPreparedForFile: true,
        message: '',
        error: false,
      };
    case 'START_OVER_APPLICATION':
      return appInitialState;
    case 'FINISH_PROJECT':
      return {
        ...state, 
        error: false,
        message: '',
        finishingProject: true,
    };
    case 'EXIT_FINISH_PROJECT':
      return {...state, finishingProject: false};
    case 'API_ERROR':
      return {
        ...state,
        error: true,
        message: action.message,
        visualisationPreparedForFile: false,
      };
    case 'API_SUCCESS':
      return {
        ...state,
        error: false,
        message: action.message,
        visualisationPreparedForFile: false,
      };
    default:
      return state;
  }
};

const appReducer = combineReducers({
  app,
});

export default appReducer;
