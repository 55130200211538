import React, {useEffect, useState} from 'react';

import {useFormikContext} from 'formik';
import styled from 'styled-components';
import {useSelector} from 'react-redux';

import {projectKeys} from 'fenceTypesConstants';

import SpanRender from '../../Design/Render/Span';
import GatewayRender from '../../Design/Render/Gateway/Gateway';
import GateRender from '../../Design/Render/Gate/Gate';

const wtrInitial = {
  span: true,
  gateway: true,
  gate: true,
};

const Visualisations = () => {
    const {values, errors, touched} = useFormikContext();
    const scale = useSelector((s) => s.app.scale);
    const visualisationPreparedForFile = useSelector((s) => s.app.visualisationPreparedForFile);
    const [whatToRender, setWhatToRender] = useState(wtrInitial);
    const calcContainerHeight = () => {
      let visualisationHeight = values.span.renderDesign ? values.span.height : null;
      // eslint-disable-next-line max-len
      visualisationHeight = values.gateway.renderDesign && values.gateway.height > visualisationHeight ? values.gateway.height : visualisationHeight;
      // eslint-disable-next-line max-len
      visualisationHeight = values.gate.renderDesign && values.gate.height > visualisationHeight ? values.gate.height : visualisationHeight;

      return (visualisationHeight + 80) / scale;
    };
    const height = calcContainerHeight();
    // do not render project with errors
    useEffect(() => {
      if (typeof errors !== 'object' || !Object.keys(errors).length || typeof touched !== 'object'
        || !Object.keys(touched).length) {
        setWhatToRender(wtrInitial);
        return;
      }
      const wtr = {...whatToRender};
      projectKeys.forEach(k => {
        // errors in height may show not the best render (e.g. when height is 1)
        wtr[k] = !(errors.hasOwnProperty(k) && touched.hasOwnProperty(k) && errors[k].height)
      });
      setWhatToRender(wtr);
    }, [errors, touched]);
    return (
      <AllVisualisations
        id="allVisualisations"
        style={{
          height: `${height}px`,
        }}
      >
        <Visualisation>
          {whatToRender.span && values.span.renderDesign && <SpanRender containerHeight={height}/>}
        </Visualisation>
        <VisualisationsSeparator
          style={{height: `${height}px`}}
          visualisationPreparedForFile={visualisationPreparedForFile}
        />
        <Visualisation>
          {whatToRender.gateway && values.gateway.renderDesign && <GatewayRender containerHeight={height}/>}
        </Visualisation>
        <VisualisationsSeparator
          style={{height: `${height}px`}}
          visualisationPreparedForFile={visualisationPreparedForFile}
        />
        <Visualisation>
          {whatToRender.gate && values.gate.renderDesign && <GateRender containerHeight={height}/>}
        </Visualisation>
      </AllVisualisations>
    );
  }
;

const AllVisualisations = styled.div`
  display: flex;
  align-items: flex-start;
  background-color: #ffffff;
  width: 100%;
  justify-content: space-evenly;
`;

const Visualisation = styled.div`
  height: 100%;
  width: 32%;
  position: relative;
`;

const VisualisationsSeparator = styled.div`
  width: 2%;
  background-color: ${(props) => (props.visualisationPreparedForFile ? '#ffffff' : '#2d3234')};
  position: relative;
  z-index: ${(props) => (props.visualisationPreparedForFile ? '9' : '1000')};
`;

export default Visualisations;
