import {STANDARD_PLATE_HEIGHT} from 'App';

export const calculateSpanRodsAmount = ({standardPlates, nonStandardPlates, amount}) => {
  return (2 * (standardPlates + nonStandardPlates)) * amount + nonStandardPlates * amount;
}

export const calculateSpanNonStandardPlates = ({elements, moduleHeight}) => {
  return elements.filter((el) => el !== STANDARD_PLATE_HEIGHT && el !== moduleHeight).length;
}

export const calculateSpanStandardPlates = ({elements}) => {
  return elements.filter((el) => el === STANDARD_PLATE_HEIGHT).length;
}
