import React, {useEffect, useState} from 'react';
import {useFormikContext} from 'formik';
import styled from 'styled-components';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheckSquare} from '@fortawesome/free-solid-svg-icons';
import {faSquare} from '@fortawesome/free-regular-svg-icons';

import {InputNumber} from 'components/Input';
import {AUTOMATION_BREAKPOINT, AUTOMATION_HEIGHT, BOTTOM_FRAME} from 'App';

import VisualisationButton from './VisualisationButton/VisualisationButton';
import { useTranslation } from 'react-i18next';

// AUTOMATION_HEIGHT_ABOVE_BREAK_POINT is the height where automation should be mounted if gate height is above the AUTOMATION_BREAKPOINT
export const AUTOMATION_HEIGHT_ABOVE_BREAK_POINT = 700;

// tries to find a rod on given height
// returns null if cannot be found (e.g. on given height is module)
const findRodOnHeight = ({height, supplementSpace, rodsStructure}) => {
  const rs = Array.from(rodsStructure).reverse();
  let sum = BOTTOM_FRAME + supplementSpace;
  let res = null;
  rs.forEach(r => {
    const next = sum + r.space + r.size;
    if (!r.module && height > sum && height < next) {
      res = {
        min: sum + r.space,
        max: next,
      };
    }
    sum = next;
  });
  return res;
}

const applyMinDistanceOnRange = (range) => {
  console.debug('Applying min distance on the range...', {
    range,
  });
  const res = {
    ...range,
    min: range.min + 50,
    max: range.max - 50,
  };
  console.debug('Min distance on the range applied', {
    range: res,
  });
  return res;

}

// Jeśli H mniejsze niż 1400 lub róne 1400 mm to wzmocnnie wypada w połowie + 100 mm toleracji.
// Jeśli H wieksze niż 1400 to wtedy ok 700 mm + 100 mm toleracji.
// Wzmocnienie nie moze wypaść w przerwie miedzy plytami.
// Wzmocnienie nie moze wypaść na brzegu płyty, od brzegu musi byc zachowane 50 mm.
const calcRange = ({height, supplementSpace, rodsStructure}) => {
  const rs = Array.from(rodsStructure).reverse();
  const desiredHeight = height <= AUTOMATION_BREAKPOINT ? Math.floor(height / 2) : AUTOMATION_HEIGHT_ABOVE_BREAK_POINT;
  console.debug('Calculating range...', {
    supplementSpace,
    desiredHeight,
    rodsStructure: rs,
  });
  let rodSum = BOTTOM_FRAME + supplementSpace + rs[0].space;
  const r = findRodOnHeight({height: desiredHeight, supplementSpace, rodsStructure});
  if (r) {
    console.debug(`Found a rod on desired height ${desiredHeight} mm height`, {
      rod: r,
    });
    const res = {
      ...r,
      max: r.max - AUTOMATION_HEIGHT,
    }
    return applyMinDistanceOnRange(res);
  }
  const calcMax = (rodSum, rodSize) => {    
    console.debug('Calculating max...', {
      rodSum,
      rodSize,
    });
    return rodSum + rodSize - AUTOMATION_HEIGHT
  };
  const shouldAddToArr = (smallestDiff, newSmallestDiff) => newSmallestDiff > 0 && newSmallestDiff <= smallestDiff;
  let range = null;
  let smallestDiff = Math.abs(rodSum - desiredHeight);
  const initMax = calcMax(rodSum, rs[0].size);
  range = {
    min: rodSum,
    max: initMax,
  };
  console.debug('Starting the loop...', {
    range
  });
  rs.forEach((rod, i, arr) => {
    if (desiredHeight === AUTOMATION_HEIGHT_ABOVE_BREAK_POINT && range) {
      return;
    }
    const nextRodSum = rodSum + rod.size + rod.space;
    if (desiredHeight && (desiredHeight <= AUTOMATION_BREAKPOINT && nextRodSum > desiredHeight / 2)) {
      range = {
        min: AUTOMATION_HEIGHT_ABOVE_BREAK_POINT - AUTOMATION_HEIGHT,
        max: AUTOMATION_HEIGHT_ABOVE_BREAK_POINT,
      };
      return;
    }
    rodSum = nextRodSum;
    const newSmallestDiff = Math.abs(rodSum - desiredHeight);
    // console.log(smallestDiff, newSmallestDiff, shouldAddToArr(smallestDiff, newSmallestDiff));
    if (!rod.module && shouldAddToArr(smallestDiff, newSmallestDiff)) {
      smallestDiff = newSmallestDiff;
      const min = rodSum;
      const max = calcMax(rodSum, rod.size);
      range = {
        min,
        max,
      };
    }
  });
  console.debug('The final range is...', {
    range,
  });
  return applyMinDistanceOnRange(range);
};

// tries to find AUTOMATION_HEIGHT_ABOVE_BREAK_POINT
// if cannot max from range is returned
const getBestAvailableHeight = ({range}) => {
  return AUTOMATION_HEIGHT_ABOVE_BREAK_POINT >= range.min && AUTOMATION_HEIGHT_ABOVE_BREAK_POINT <= range.max ? AUTOMATION_HEIGHT_ABOVE_BREAK_POINT : range.max;
}

const validateAutomationForASymmetricalGates = ({automation, space, supplementSpace, rodsStructure}) => {
  const automationHeight = automation?.height + 40;
  const plates = rodsStructure?.length - 1;
  let start = 80 + supplementSpace;
  for (var i = 0; i <= plates; i +=1 ) {
    start += space;
    start += 302;
    if (start > automationHeight) {
      const upperPlate = start - automationHeight;
      const lowerPlate = 302 - upperPlate - 80;
      if ((upperPlate < 60 || upperPlate > 162) && (lowerPlate < 60 || lowerPlate > 162)) {
        return [false, `Dodatkowy poziom niemozliwy do zamontowania: płyty ${upperPlate} mm i/lub ${lowerPlate} niezgodne z wymaganiami.`];
      } else {
        return [true, [700, upperPlate, lowerPlate]];
      }
    }
  }
}

const Automation = () => {
  const formik = useFormikContext();
  const { t } = useTranslation();
  const {height, space, supplementSpace, rodsStructure, gateType, automation} = formik.values.gate
  const [info, setInfo] = useState('');
  const [wing, setWing] = useState(false);
  useEffect(() => {
    if (formik.values.gate?.rightWingWidth && formik.values.gate?.leftWingWidth) {
      const chosenWingWidth = (formik.values.gate.leftWingWidth <= formik.values.gate.rightWingWidth) ? formik.values.gate.rightWingWidth : formik.values.gate.leftWingWidth;
      if (chosenWingWidth >= 2999) {
        if (!automation.checked) {
          formik.setFieldValue('gate.automation.checked', true);
        }
        setWing(true);
      } else {
        if (wing && automation.checked) {
          formik.setFieldValue('gate.automation.checked', false);
          setWing(false);
          return;
        }
        setWing(false);
      }
    }
    if (gateType === 'singleLeafGate') {
      if (formik.values.gate?.width >= 3000) {
        if (!automation.checked) {
          formik.setFieldValue('gate.automation.checked', true);
        }
        setWing(true);
      } else {
        if (wing && automation.checked) {
          formik.setFieldValue('gate.automation.checked', false);
          setWing(false);
          return;
        }
        setWing(false);
      }
    }
    if (!rodsStructure?.length) return;
    if (!automation.checked) {
      formik.setFieldValue('gate.automation.range', []);
      formik.setFieldValue('gate.automation.height', null);
      return;
    }
    const range = calcRange({height, supplementSpace, rodsStructure});
    const aut = {
      ...automation,
      range
    }
    if (range) {
      const valid = validateAutomationForASymmetricalGates({automation, space, supplementSpace, rodsStructure});
      if (wing && valid?.length === 2 && valid[0]) {
        formik.setFieldValue('gate.automationWing', valid[1]);
      }
      aut.height = wing ? 700 : getBestAvailableHeight({range});
      setInfo(`${t('automationHeightConditions')} ${range.min} mm ${t('to')} ${range.max} mm.`);
    } else {
      setInfo('');
    }
    formik.setFieldValue('gate.automation', aut);
  }, [height, space, supplementSpace, rodsStructure, automation.checked])
  let error = formik.touched.gate?.automation?.height && formik.errors.gate?.automation?.height && !wing ? <Error><span>{t('note')}</span> {formik.errors.gate?.automation?.height} {info}</Error> : '';
  const valid = validateAutomationForASymmetricalGates({automation, space, supplementSpace, rodsStructure});
  if (wing && valid?.length === 2 && !valid[0]) {
    error = valid[1];
  }
  const toggle = () => formik.setFieldValue('gate.automation.checked', !automation.checked);
  const supported = ['gateTilting', 'singleLeafGate', 'foldingGate', 'nonSymmetricalGate'].includes(gateType);

  if (!supported) return null;
  return (
    <div>
      <InputContainer>
        <Input type="checkbox" id="automation" checked={automation.checked} onChange={toggle}/>
        {automation.checked && <AutomationCheck className="btn" onClick={toggle} data-sentry="automation">
          <FontAwesomeIcon icon={faCheckSquare}/>
        </AutomationCheck>}
        {!automation.checked && <AutomationCheck className="btn" onClick={toggle} data-sentry="automation">
          <FontAwesomeIcon icon={faSquare}/>
        </AutomationCheck>}
        <Label className="form-check-label" htmlFor="automation">
          {t('automationReinforcement')}</Label>
      </InputContainer>
      <VisualisationButton/>
      {!!automation.range && automation.checked && <div className="form-group">
      {!wing && (
        <InputNumberStyled
          change={(v) => {
            formik.setFieldValue('gate.automation.height', parseInt(v))
          }}
          val={formik.values.gate.automation.height}
          info={error}
          label={t('automationHeight')}
          name="gate.automation.height"
          onBlur={formik.handleBlur}
          error={error}
        />
      )}
      {wing && valid?.length === 2 && (
        <div>
          <p>
            Dodatkowy pion na wysokości: 700mm - wartość nie może zostać zmieniona
          </p>
          {!valid[0] && (
            <b style={{ color: 'red' }}>
              {error}
            </b>)
          }
        </div>
      )}
      </div>}
    </div>
  );
};

const InputContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Input = styled.input`
  display: none;
`;

const AutomationCheck = styled.button`
  color: #44AF69;
  font-size: 1.4rem;
  padding: 0;
`;

const Label = styled.label`
  cursor: pointer;
  margin-left: .6rem;
`;

const Error = styled.div`
  span {
    font-weight: bold;
  }
`;

const InputNumberStyled = styled(InputNumber)`
  .form-control {
    background: ${props => props.error ? '#E50019' : '#626567'};
  }
`;

export default Automation;
