import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  background: #f44336;
`;

const BadSpace = ({ space, scale }) => {
  const spaceScaled = space / scale;

  return (
    <Container style={{ height: spaceScaled }} />
  );
};

export default BadSpace;
