import React from 'react';
import {useFormikContext} from 'formik';
import styled from 'styled-components';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTrashAlt} from '@fortawesome/free-solid-svg-icons';

const exclude = ({formik, type}) => {
  formik.setFieldValue(`${type}.chosen`, false);
  formik.setFieldValue(`${type}.renderDesign`, false);
  formik.setFieldValue('gate.gateType', '');
}

const ExcludeButton = ({type}) => {
  const formik = useFormikContext();
  if (!formik.values[type].chosen) return null;
  return (
    <Btn type="button" className="btn" onClick={() => exclude({formik, type})}>
      <Icon icon={faTrashAlt}/>
    </Btn>
  );
};

const Btn = styled.button`
  color: #575B5D;
  font-size: 1.5rem;
  margin-right: 2rem;
  padding: 0;

  &:hover {
    color: #424749;
  }
`;

const Icon = styled(FontAwesomeIcon)`
  margin-right: 1rem;
`;

export default ExcludeButton;
