import {hinges, HINGES_180, HINGES_LOCINOX_MAMMOTH, HINGES_STANDARD} from 'fenceTypesConstants';
import React from 'react';
import {useFormikContext} from 'formik';

import {OPTION_P302_WITH_MODULE} from 'App';
import {onPropertyChange} from 'Design/Design';
import {Error, InputNumber} from 'components/Input';

import SupplementSpace from './SupplementSpace';

import {
  GreyInputsContainer, HingesSelect, InputsContainer, Label
} from '../Project';
import { useTranslation } from 'react-i18next';

const BottomGateway = () => {
  const formik = useFormikContext();
  const { t } = useTranslation();

  if (!formik.values.gateway.chosen) return null;
  return (
    <>
      <GreyInputsContainer automation={formik.values.gate.automation.checked}>
        <div className="form-group">
          <InputNumber
            change={(v) => onPropertyChange(formik, 'gateway', 'space', v)}
            val={formik.values.gateway.space}
            info={formik.touched.gateway?.space && formik.errors.gateway?.space}
            label={t('spaceInput')}
            name="gateway.space"
            onBlur={formik.handleBlur}
          />
        </div>
        <SupplementSpace type="gateway"/>
      </GreyInputsContainer>
      <InputsContainer>
        {formik.values.option === OPTION_P302_WITH_MODULE && (
          <>
            <div className="form-group">
              <InputNumber
                change={(v) => onPropertyChange(formik, 'gateway', 'moduleHeight', v)}
                val={formik.values.gateway.moduleHeight}
                info={formik.touched.gateway?.moduleHeight && formik.errors.gateway?.moduleHeight}
                label={t('moduleHeightInput')}
                name="gateway.moduleHeight"
                onBlur={formik.handleBlur}
                
              />
            </div>
          </>
        )}
        <div className="form-group">
          <InputNumber
            change={(v) => onPropertyChange(formik, 'gateway', 'amount', v)}
            val={formik.values.gateway.amount}
            info={formik.touched.gateway?.amount && formik.errors.gateway?.amount}
            label={t('gatewaysAmountInput')}
            name="gateway.amount"
            onBlur={formik.handleBlur}
          />
        </div>
        <div className="form-group">
          <Label id="gatewayHinges">{t('hinges')}</Label>
          <HingesSelect
            className="form-control"
            onChange={(e) => onPropertyChange(formik, 'gateway', 'hinges', e.currentTarget.value)}
            value={formik.values.gateway.hinges}
            name="gateway.hinges"
            onBlur={formik.handleBlur}
            >
            <option value="" disabled>{t('chooseHingesInput')}</option>
            <option value={HINGES_STANDARD}>{t('standardHinges')}</option>
            <option value={HINGES_180}>{t('180Hinges')}</option>
            <option value={HINGES_LOCINOX_MAMMOTH}>{t('locinoxHinges')}</option>
          </HingesSelect>
          <Error>{formik.touched.gateway?.hinges && formik.errors.gateway?.hinges && formik.errors.gateway.hinges}</Error>
        </div>
      </InputsContainer>
    </>
  );
};

export default BottomGateway;
