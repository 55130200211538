import i18next from '../../Translation/config';
import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { useTranslation } from 'react-i18next';


const InfoGate = () => {
  return <div className="platesWidthKey">
    {i18next.language === 'en' && (
    <p>
      The value entered in the "Gate Width" field results from the following conditions:
      <ul>
        <li>Self-Supporting Gate: (Gate Width + 100) - 160 for L = 2000mm</li>
        <li>Self-Supporting Gate: ((Gate Width + 100) - 240) / 2 for L = 4499mm</li>
        <li>Self-Supporting Gate: ((Gate Width + 100) - 320) / 3 for L = 5800mm</li>
        <li>Single-Leaf Gate (standard hinges): Gate Width - 275 for L = 2499 mm</li>
        <li>Single-Leaf Gate (180-degree hinges): Gate Width - 225 for L = 2499 mm</li>
        <li>Single-Leaf Gate (standard hinges): (Gate Width - 360) / 2 for L = 2499 mm</li>
        <li>Single-Leaf Gate (180-degree hinges): (Gate Width - 305) / 2 for L = 2999 mm</li>
        <li>Single-Leaf Gate (standard hinges): (Gate Width - 370) / 2 for L = 3000-3750 mm</li>
        <li>Single-Leaf Gate (180-degree hinges): (Gate Width - 330) / 2 for L = 3000-3750 mm</li>
        <li>KNS Folding Gate: (Gate Width - 820) / 4</li>
        <li>KNS Folding Gate (If one mounting strip): (Gate Width - 900) / 4</li>
        <li>KNS Folding Gate (If two mounting strips): (Gate Width - 980) / 4</li>
        <li>Non-symmetrical Gate (standard hinges): (Gate Width - 290) for wing L = 2499mm</li>
        <li>Non-symmetrical Gate (180-degree hinges): (Gate Width - 190) for wing L = 2499mm</li>
        <li>Non-symmetrical Gate (standard hinges): ((Gate Width - 370) / 2) for wing L = 2999mm</li>
        <li>Non-symmetrical Gate (180-degree hinges): ((Gate Width - 270) / 2 ) for wing L = 2999mm</li>
        <li>Non-symmetrical Gate (standard hinges): ((Gate Width - 400) / 2 ) for wing L = 3750mm</li>
        <li>Non-symmetrical Gate (180-degree hinges): ((Gate Width - 320) / 2 ) for wing L = 3750mm</li>
      </ul>
      Gate Width - value entered in the "Width of gateway/gate" field
    </p>
    )}
    {i18next.language === 'pl' && (
    <p>
      Wartość podana w polu "Szerokość pola" wynika z poniższych warunków:
      <ul>
        <li>Brama samonośna: (Światło + 100) - 160 do L = 2000mm</li>
        <li>Brama samonośna: ((Światło + 100) - 240) / 2 do L = 4499mm</li>
        <li>Brama samonośna: ((Światło + 100) - 320) / 3 do L = 5800mm</li>
        <li>Brama jednoskrzydłowa (zawiasy standardowe): Światło - 275 do L = 2499 mm</li>
        <li>Brama jednoskrzydłowa (zawias 180 stopni): Światło - 225 do L = 2499 mm</li>
        <li>Brama jednoskrzydłowa (zawiasy standardowe): (Światło - 360) / 2 od L = 2499 mm</li>
        <li>Brama jednoskrzydłowa (zawias 180 stopni): (Światło - 305) / 2 do L = 2999 mm</li>
        <li>Brama jednoskrzydłowa (zawiasy standardowe): (Światło - 370) / 2 L = 3000-3750 mm</li>
        <li>Brama jednoskrzydłowa (zawias 180 stopni): (Światło - 330) / 2 do L = 3000-3750 mm</li>
        <li>Brama łamana KNS: (Światło - 820) / 4</li>
        <li>Brama łamana KNS (Jeżeli jedna listwa montażowa): (Światło - 900) / 4</li>
        <li>Brama łamana KNS (Jeżeli dwie listwy montażowe): (Światło - 980) / 4</li>
        <li>Brama niesymetryczna (zawiasy standardowe): (Światło - 290) do L skrzydła 2499mm</li>
        <li>Brama niesymetryczna (zawias 180 stopni): (Światło - 190) do L skrzydła 2499mm</li>
        <li>Brama niesymetryczna (zawiasy standardowe): ((Światło - 370) / 2) do L skrzydła 2999mm</li>
        <li>Brama niesymetryczna (zawias 180 stopni): ((Światło - 270) / 2 ) do L skrzydła 2999mm</li>
        <li>Brama niesymetryczna (zawiasy standardowe): ((Światło - 400) / 2 ) do L skrzydła 3750mm</li>
        <li>Brama niesymetryczna (zawias 180 stopni): ((Światło - 320) / 2 ) do L skrzydła 3750mm</li>
      </ul>
      Światło - wartość wpisana w pole "Szerokość furtki/bramy"
    </p>
    )}
  </div>;
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };
  
  export default function InfoGatePopup() {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const { t } = useTranslation();
  
    return (
      <div>
        <Button 
            onClick={handleOpen}
            style={{ padding: "0.4rem 2rem", background:"#44AF69", color:"white", marginBottom: "10px", borderRadius: "25px" }}
        >{t('popupTitle')}
        </Button>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <InfoGate />
          </Box>
        </Modal>
      </div>
    );
  }
