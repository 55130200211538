import {useFormikContext} from 'formik';
import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import Rods from './Rods';
import SpaceFromOtherProjects from './SpaceFromOtherProjects';

const LeftPole = styled.div`
  height: 100%;
  width: 15px;
  background-image: url('img/span/left.png');
  display: flex;
`;

const RightPole = styled.div`
  height: 100%;
  width: 15px;
  background-image: url('img/span/right.png');
`;

const RenderContainer = styled.div`
  display: flex;
  width: 100%;
`;

const Span = ({ containerHeight }) => {
  const formik = useFormikContext();
  const scale = useSelector((s) => s.app.scale);
  const heightScaled = formik.values.span.height / scale;

  if (formik.values.span.renderDesign) {
    return (
      <div
        className="col"
        style={{ background: '#ffffff' }}
      >
        <RenderContainer
          id="spanRender"
          style={{
            height: `${heightScaled}px`,
            background: '#ffffff',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <LeftPole />
          <Rods
            design={formik.values.span}
            scale={scale}
            projectType="span"
            moduleHeight={formik.values.span.moduleHeight || null}
          />
          <RightPole />
          {formik.values.gateway.renderDesign && (
            <SpaceFromOtherProjects
              containerHeight={containerHeight}
              type="gateway"
              side={'spaceSideRight'}
            />
          )}
          {formik.values.gate.renderDesign && (
            <SpaceFromOtherProjects
              containerHeight={containerHeight}
              type="gate"
              side={'spaceSideRight'}
            />
          )}
        </RenderContainer>
      </div>
    );
  }
  return (
    <div className="col" />
  );
};

export default Span;
