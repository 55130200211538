import {AUTOMATION_HEIGHT} from 'App';
import {useFormikContext} from 'formik';
import React from 'react';
import {useSelector} from 'react-redux';
import styled from 'styled-components';

const Container = styled.div`
  cursor: move;
  position: absolute;
  right: 0;
  text-align: right;
  padding: 0 .2rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  color: #fff;;
  background: ${(props) => props.error ? '#ff0000' : 'green'}
`;

const Automation = () => {
  const formik = useFormikContext();
  const scale = useSelector((state) => state.app.scale);
  const {gateType, automation} = formik.values.gate;
  const bottom = automation.height / scale;
  const supported = ['gateTilting', 'singleLeafGate', 'foldingGate'].includes(gateType);
  if (!supported || !automation.range || !automation.checked) return null;
  return <Container style={{
    height: `${AUTOMATION_HEIGHT / scale}px`,
    bottom: `${bottom}px`
  }} error={formik.touched?.gate?.automation?.height && formik.errors?.gate?.automation?.height}>
    <span>automatyka</span>
    <span>H: {automation.height} mm</span>
  </Container>
};

export default Automation;
