import React, {useContext} from 'react';

import {hinges} from 'fenceTypesConstants';

import ModuleData from '../ModuleData';
import Context from '../context';
import { useTranslation } from 'react-i18next';
import GatewayCommon from './GatewayCommon';
import SummaryPageGateway from 'Pdf/SummaryPage/SummaryPageGateway';

const GatewayPage = ({ render }) => {
  const {values} = useContext(Context);
  const {chosen, width, height, space} = values.gateway;
  const { t } = useTranslation();
  if (!chosen) return null;

  return (
    <div className="new-page">
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="pdfHeader">
              <h2>{t('gateway')}</h2>
              <div className="headerLine"/>
            </div>
            <div className="fenceVisualisation">
              <img src={`${render}`} alt=""/>
            </div>
            <div className="fenceTypeData">
              <GatewayCommon/>
              <p>
                <b>{t('width')}:</b> {width} mm
              </p>
              <p>
                <b>{t('height')}:</b> {height} mm
              </p>
              <p>
                <b>{t('space')}:</b> {space} mm
              </p>
              <p><b>{t('color')}:</b> {values.color}</p>
              <p><b>{t('chinges')}:</b> {hinges[values.gateway.hinges]}</p>
              <ModuleData type="gateway" />
              <SummaryPageGateway />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GatewayPage;
