import React from 'react';
import styled from 'styled-components';
import Rods from '../Rods';

const TopBar = styled.div`
  height: 20px;
  width: calc(100% + 4px);
  background-image: url('img/gateway/top.png');
  background-size: contain;
  display: flex;
`;

const BottomBar = styled.div`
  height: 20px;
  width: calc(100% + 4px);
  background-image: url('img/gateway/bottom.png');
  background-size: contain;
  display: flex;
`;

const Container = styled.div`
  width: 58%;
`;

const Body = ({ design, scale, projectType }) => (
  <Container>
    <TopBar />
    <Rods design={design} scale={scale} projectType={projectType} />
    <BottomBar />
  </Container>
);

export default Body;
