import {projectKeys} from 'fenceTypesConstants';
import projectKeysHuman from 'App';
import {calculateMissingSpace} from 'Project/Bottom/SupplementSpace';
import React from 'react';

import styled from 'styled-components';
import {useFormikContext} from 'formik';
import {useDispatch, useSelector} from 'react-redux';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTimes} from '@fortawesome/free-solid-svg-icons';

const projects = [
  'span',
  'gateway',
  'gate',
];

const guessLastIssue = (values, touched, errors) => {
  const atLeastOneFinished = values.span.chosen || values.gateway.chosen || values.gate.chosen;
  if (!atLeastOneFinished) return '...ukończ przynajmniej jeden projekt';
  const hingesIssue = (values.gateway.chosen && errors.gateway?.hinges) ||
    (values.gate.chosen && (values.gate.gateType === 'gateTilting' || values.gate.gateType === 'singleLeafGate' || values.gate.gateType === 'foldingGate' || values.gate.gateType === 'nonSymmetricalGate') && errors.gate?.hinges);
  if (hingesIssue) return '...by wysłać wybierz zawiasy';
  const issueInProject = projects.some(p => {
    return values[p].chosen && errors[p] && Object.keys(errors[p]).length;
  })
  if (issueInProject) return '...by wysłać projekt skoryguj błędy';
  const missingSpaceKey = projectKeys.find((key) => {
    const ms = calculateMissingSpace({
      space: values[key].space,
      supplementSpace: values[key].supplementSpace,
    });
    return ms ? key : false;
  })
  if (missingSpaceKey) return `...popraw projekt ${projectKeysHuman[missingSpaceKey]}.`;
  return '';
}

const FinishForm = () => {
  const formik = useFormikContext();
  const dispatch = useDispatch();
  const finishingProject = useSelector((state) => state.app.finishingProject);
  const error = useSelector((state) => state.app.error);
  const message = useSelector((state) => state.app.message);
  if (!finishingProject) return null;
  const lastIssue = guessLastIssue(formik.values, formik.touched, formik.errors);

  return (
    <Modal className="modal" tabIndex="-1" role="dialog">
      <div className="modal-dialog" role="document">
        <Inner className="modal-content">
          <HeaderContainer>
            <Header>Jeszcze jeden krok</Header>
            <Close
              type="button"
              data-sentry="closeFinishForm"
              className="btn"
              onClick={() => dispatch({type: 'EXIT_FINISH_PROJECT'})}>
              <FontAwesomeIcon icon={faTimes}/>
            </Close>
          </HeaderContainer>
          <div>
            <SubHeader>Aby zakończyć projektowanie, wpisz Twój adres<br/>email i nazwę projektu i naciśnij "Wyślij projekt".<br/>Możesz wcześniej sprawdzić podgląd projektu.</SubHeader>
            <div>
              <Row>
                <div>
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Adres email"
                    name="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    required
                  />
                  <span>{formik.touched?.email && formik.errors?.email}</span>
                </div>
                <div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Nazwa projektu"
                    name="projectName"
                    value={formik.values.projectName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  <span>{formik.touched?.projectName && formik.errors?.projectName}</span>
                </div>
                <Send className="btn" type="button" disabled={lastIssue} onClick={formik.handleSubmit}
                      data-sentry="send"
                >
                  Wyślij projekt
                </Send>
              </Row>
              <ResponseContainer error={error}>
                <span>{message}</span>
                {formik.isSubmitting && <span>Trwa przesyłanie...</span>}
                {lastIssue && <span>{lastIssue}</span>}
              </ResponseContainer>
            </div>
          </div>
        </Inner>
      </div>
    </Modal>
  );
};

const Modal = styled.div`
  display: block;
  background-color: rgba(0, 0, 0, 0.9);

  .modal-dialog {
    max-width: 850px;
  }
`;

const Inner = styled.div`
  padding: 3rem;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
`;

const Header = styled.h1`
  font-weight: 400;
`;

const Close = styled.button`
  font-size: 1.5rem;
  padding: 0;
`;

const SubHeader = styled.div`
  font-size: 2rem;
  font-weight: 300;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 2rem;

  input {
    border-radius: 22px;
    background: #e6e6e6;
    color: #000;
    width: 228px;
  }

  input::-webkit-input-placeholder {
    color: #000;
  }

  span {
    color: #ff0000;
    display: inline-block;
    margin-top: .5rem;
  }
`;

const Send = styled.button`
  background: #44AF69;
  padding: .4rem 0;
  color: #ffff;
  width: 228px;

  &:hover {
    color: #ffff;
  }
`;

const ResponseContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  color: ${props => props.error ? '#ff0000' : 'inherit'};
`;

export default FinishForm;
