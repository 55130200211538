import React from 'react';
import styled from 'styled-components';

const Top = styled.div`
  height: 18px;
  width: 52px;
  background-image: url('img/gateway/top-left.png');
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
`;

const Pole = styled.div`
  height: calc(100% - 18px - 30px);
  width: 52px;
  background-image: url('img/gateway/left.png');
  background-repeat: repeat;
  background-size: contain;
  display: flex;
`;

const Bottom = styled.div`
  height: 30px;
  width: 52px;
  background-image: url('img/gateway/bottom-left.png');
  background-size: cover;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: calc(100% + 14px);
`;

const LeftPole = () => (
  <Container>
    <Top />
    <Pole />
    <Bottom />
  </Container>
);

export default LeftPole;
