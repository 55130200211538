import React from 'react';
import styled from 'styled-components';

const Top = styled.div`
  height: 26px;
  width: 62px;
  margin-top: -7px;
  background-image: url('img/gateSelfSupporting/top-left.png');
  background-size: cover;
  display: flex;
`;

const Pole = styled.div`
  background-size: contain;
  height: calc(100% - 40px);
  width: 49px;
  background-image: url('img/gateSelfSupporting/left.png');
  background-repeat: repeat;
  display: flex;
`;

const Bottom = styled.div`
  height: 58px;
  width: 49px;
  background-image: url('img/gateSelfSupporting/bottom-left.png');
  background-size: cover;
  background-repeat: no-repeat;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const LeftPole = () => (
  <Container>
    <Top />
    <Pole />
    <Bottom />
  </Container>
);

export default LeftPole;
