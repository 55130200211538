import React, {useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronLeft, faChevronRight} from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';

import Nav from './Nav';

// TODO organise context for it
const slides = [
  {
    src: '/img/wzmocnienie/1.png',
    alt: 'wzmocnienie pod automatykę 1',
  },
  {
    src: '/img/wzmocnienie/2.png',
    alt: 'wzmocnienie pod automatykę 2',
  },
  {
    src: '/img/wzmocnienie/3.png',
    alt: 'wzmocnienie pod automatykę 3',
  },
];

const Slider = ({close}) => {
  const [current, setCurrent] = useState(0);
  const s = slides[current];
  const previous = () => setCurrent(c => {
    const n = c - 1;
    return n < 0 ? slides.length - 1 : n;
  });
  const next = () => setCurrent(c => {
    const n = c + 1;
    return n >= slides.length ? 0 : n;
  });

  return (
    <div>
      <Inner>
        <div>
          <Button className="btn" type="button" onClick={previous}>
            <Icon icon={faChevronLeft}/>
          </Button>
        </div>
        <SlideContainer>
          <Slide src={s.src} alt={s.alt}/>
        </SlideContainer>
        <div>
          <Button className="btn" type="button" onClick={next}>
            <Icon icon={faChevronRight}/>
          </Button>
        </div>
      </Inner>
      <Nav slides={slides} current={current} setCurrent={setCurrent}/>
      <CloseContainer>
        <CloseButton className="btn btn-link" type="button" onClick={close}>Zamknij</CloseButton>
      </CloseContainer>
    </div>
  );
};

const Inner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
`;

const SlideContainer = styled.div`
`;

const Slide = styled.img`
  width: auto;
  max-height: 270px;
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  padding: 0;
  &:focus {
    box-shadow: none;
  }
`;

const Icon = styled(FontAwesomeIcon)`
  font-size: 1.3rem;
`;

const CloseContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const CloseButton = styled.button`
  color: #000;
  padding: 0;
  &:hover {
    text-decoration: none;
    color: #000;
  }
`;

export default Slider;
