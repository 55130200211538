import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faMinus, faPlus} from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';

export const Error = styled.span`
  color: #ff0000;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  font-size: .8rem;
`;

export const Input = styled.input`
  border-radius: 22px;
  border: none;
  font-weight: bold;
  background: #626567;
  color: #ffffff;
  margin-top: 0.5rem;

  &:focus {
    outline: none;
    border: none;
    box-shadow: none;
    background: #626567;
    color: #ffffff;
  }

  &:disabled {
    background-color: #f2f2f2;
    opacity: 1;
    color: #000000;
    border: 1px solid #d6d6d6;
  }
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
`;

const InputBtnContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 0.75rem;
`;

const InputBtn = styled.button`
  border-radius: 15px;
  background: #d9d9d9;
  border: none;
  padding: 0.25rem 0.5rem;

  &:focus {
    border: none;
    outline: none;
  }
`;

const ValidationMessage = styled.small`
  color: #ff0000;
`;

export const InputNumber = (
  {
    change,
    val,
    info,
    label,
    onBlur,
    name,
    className
  },
) => (
  <Container className={className}>
    <Label>
      {label}
      <InputContainer>
        <Input
          type="number"
          className="form-control"
          step={1}
          value={val || ''}
          onChange={(e) => change(e.currentTarget.value)}
          onBlur={onBlur}
          name={name}
        />
        <InputBtnContainer>
          <InputBtn
            style={{marginRight: '0.5rem'}}
            onClick={() => change(val + 1)}
            name={name}
            onBlur={onBlur}
            type="button"
            tabindex={-1}
          >
            <FontAwesomeIcon icon={faPlus} style={{color: '#000000'}}/>
          </InputBtn>
          <InputBtn
            onClick={() => change(val - 1)}
            name={name}
            onBlur={onBlur}
            type="button"
            tabindex={-1}
          >
            <FontAwesomeIcon
              icon={faMinus}
              style={{color: '#000000'}}
            />
          </InputBtn>
        </InputBtnContainer>
      </InputContainer>
    </Label>
    <ValidationMessage className="form-text">{info}</ValidationMessage>
  </Container>
);
