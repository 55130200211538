import axios from 'axios';
import * as Sentry from '@sentry/react';

const generatePdf = (data) => {
  // open pdf html in new tab
  // window.open().document.write(data);

  return new Promise((resolve, reject) => axios.request('https://www.api.pdf.konsport.com', {
    method: 'POST',
    headers: {
      'Content-Type': 'text/html; charset=utf-8',
      'X-Project-Name': 'xps',
      'X-Pdf-Name': 'szczegoly-projektu',
      'X-Include-Footer': '1',
      'X-Include-Page-Number': '1',
    },
    data,
  }).then(({ data }) => resolve(data.url)).catch((err) => {
    Sentry.captureException(e);
    reject(err);
    // eslint-disable-next-line no-console
    console.error(err);
  }));
};
export default generatePdf;
