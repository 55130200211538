import {OPTION_P302_WITH_MODULE} from 'App';
import React, {useContext} from 'react';
import Context, { calculateSpacesAmount } from '../context';
import { useTranslation } from 'react-i18next';

const SummaryPageSpan = () => {
  const {values, spanNonStandardPlates: nonStandardPlates, spanStandardPlates: standardPlates} = useContext(Context);
  const {chosen, amount, height, rodsStructure} = values.span;
  if (!chosen) return null;
  const totalPlates = (standardPlates + nonStandardPlates) * amount;
  const spacesAmount = calculateSpacesAmount(rodsStructure);
  const { t } = useTranslation();
  return (
  <div className="sumUpData">
    <p>
      <b>{t('totalPlatesAmount')}:</b> {totalPlates} szt
    </p>
    <p>
      <b>{t('rivetsAmount')}:</b> {totalPlates * 2} szt
    </p>
    <p>
      <b>{t('spaceHigherThan0Amount')}:</b> {spacesAmount} szt
    </p>
    <p>
      <b>{t('verticalMountingCsections')}:</b> {amount * 2} szt
    </p>
    <p>
      <b>{t('verticalMountingCsectionsHeight')}:</b> {height} mm
    </p>
    {values.option === OPTION_P302_WITH_MODULE && <p>
      <b>{t('modulesAmount')}:</b> {amount} szt
    </p>}
  </div>
  );
};

export default SummaryPageSpan;
