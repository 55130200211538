import React from 'react';
import styled from 'styled-components';
import ReactDOMServer from 'react-dom/server';
import { useTranslation } from 'react-i18next';
import Pdf from 'Pdf/Pdf';
import createPdfVariables from 'Pdf/commonFunctions';
import wrapPdfWithHtml from 'Pdf/wrapPdfWithHtml';

const Button = styled.button`
  background: #0165c7;
  padding: .4rem 2rem;
  color: #fff;
  &:hover {
    color: #ffff;
  }
`;

const PreviewPDFButton = ({className, values}) => {
  const generatePDF = async () => {
    const vars = await createPdfVariables(values);
    const pdf = ReactDOMServer.renderToStaticMarkup(
      <Pdf
        spanElements={vars.spanElements}
        spanRender={vars.spanRender}
        gatewayElements={vars.gatewayElements}
        gatewayRender={vars.gatewayRender}
        gateElements={vars.gateElements}
        gateRender={vars.gateRender}
        values={values}
      />,
    );
    const html = wrapPdfWithHtml(pdf);
    const newWindow = window.open('','');
    newWindow.document.write(html);
    newWindow.document.close();
    newWindow.focus();
    newWindow.print();
  };
  const { t } = useTranslation();

  return (
    <Button
      type="button"
      className={`${className} btn`}
      onClick={generatePDF}
    >
      {t('projectPDF')}
    </Button>
  );
};

export default PreviewPDFButton;
