import React from 'react';

import {useFormikContext} from 'formik';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import Color from 'GettingStarted/Color';
import ColorModule from 'GettingStarted/ColorModule';
import {OPTION_P302, OPTION_P302_WITH_MODULE} from 'App';

import P302 from 'GettingStarted/P302';
import P302Module from 'GettingStarted/P302Module';

const Container = styled.div`
`;

const TitleContainer = styled.div`
  margin: 0 0 2rem;
`;

const Title = styled.h1`
  color: #ffffff;
  font-weight: bold;
  font-size: 3.65rem;
`;

const Options = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const OptionContainer = styled.div`
  background: ${props => props.shadow ? 'rgba(255, 255, 255, .5)' : '#fff'};
  width: calc(50% - 1rem);
`;

const OptionButton = styled.button`
  display: flex;
  flex-direction: column;
  background: transparent;
  width: 100%;
  height: 100%;
`;

const ImgContainer = styled.div`
  width: 100%;
  text-align: center;
  margin: 1rem 0 4rem 0;
`;

const Img = styled.img`
  height: 265px;
  width: auto;
`;

export const OptionTitle = styled.h2`
  font-weight: bold;
  margin-top: 3rem;
  margin-left: 1rem;
`;

export const SmallerP = styled.p`
  font-size: 1.25rem;
  margin-left: 4rem;
  text-align: left;
`;

const GettingStarted = () => {
  const formik = useFormikContext();
  const { t } = useTranslation();

  if ((formik.values.option === OPTION_P302 && formik.values.color) ||
    (formik.values.option === OPTION_P302_WITH_MODULE && formik.values.color && formik.values.moduleColor)) return null;
  return (
    <Container>
      <div className="container-xxl">
        <div className="row">
          <div className="col">
            <TitleContainer>
              <Title>{t('title')}</Title>
            </TitleContainer>
            <Options>
              {formik.values.option !== OPTION_P302 && <OptionContainer shadow={formik.values.option === OPTION_P302_WITH_MODULE}>
                <OptionButton
                  type="button"
                  data-sentry="chooseP302"
                  onClick={() => formik.setFieldValue('option', OPTION_P302)}
                >
                  <P302/>
                  <ImgContainer>
                    <Img src="/img/gettingStarted.png" alt=""/>
                  </ImgContainer>
                </OptionButton>
              </OptionContainer>}
              {formik.values.option === OPTION_P302 && <OptionContainer>
                <P302/>
                <Color/>
              </OptionContainer>}
              {formik.values.option !== OPTION_P302_WITH_MODULE && <OptionContainer shadow={formik.values.option === OPTION_P302}>
                <OptionButton
                  type="button"
                  data-sentry="chooseP302WithModule"
                  onClick={() => formik.setFieldValue('option', OPTION_P302_WITH_MODULE)}
                >
                  <P302Module/>
                  <ImgContainer>
                    <Img src="/img/gettingStarted-module.png" alt=""/>
                  </ImgContainer>
                </OptionButton>
              </OptionContainer>}
              {formik.values.option === OPTION_P302_WITH_MODULE && <OptionContainer>
                <P302Module/>
                <Color/>
                <ColorModule/>
              </OptionContainer>}
            </Options>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default GettingStarted;
