import React from 'react';
import {useFormikContext} from 'formik';

import TypeTitle from 'Project/TypeTitle/TypeTitle';
import {onPropertyChange} from 'Design/Design';
import {InputNumber} from 'components/Input';

import {InputsContainer} from '../Project';
import CopyValuesBtn from './CopyValuesBtn';
import { useTranslation } from 'react-i18next';

const TopSpan = () => {
  const formik = useFormikContext();
  const { t } = useTranslation();

  return (
    <>
      <TypeTitle type="span"/>
      {formik.values.span.chosen && <InputsContainer>
        <CopyValuesBtn type="span"/>
        <div className="form-group">
          <InputNumber
            change={(v) => onPropertyChange(formik, 'span', 'width', v)}
            val={formik.values.span.width}
            info={formik.touched.span?.width && formik.errors.span?.width}
            label={t('spanWidth')}
            name="span.width"
            onBlur={formik.handleBlur}
            
          />
        </div>
        <div className="form-group">
          <InputNumber
            change={(v) => onPropertyChange(formik, 'span', 'height', v)}
            val={formik.values.span.height}
            info={formik.touched.span?.height && formik.errors.span?.height}
            label={t('spanHeight')}
            name="span.height"
            onBlur={formik.handleBlur}
            
          />
        </div>
      </InputsContainer>}
    </>
  );
};

export default TopSpan;
