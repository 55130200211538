import React from 'react';
import styled from 'styled-components';
import {useFormikContext} from 'formik';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faAngleLeft} from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
  margin-left: 4rem;
`;

const BackButton = styled.button`
  display: flex;
  align-items: center;
  .svg-inline--fa {
    margin-right: 1rem;
  }
`;

const Desc = styled.span`
  font-weight: bold;
  margin-top: 3rem;
  margin-bottom: 1rem;
  display: inline-block;
  font-size: 1.2rem;
`;

const Buttons = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 2rem;
`;

const Button = styled.button`
  background: ${props => props.color};
  border-radius: 22px;
  height: 3.5rem;
  width: 8rem;
  margin: 0 1rem 1rem 0;
  color: ${props => props.invertColor ? 'inherit' : '#fff'};
  font-size: .8rem;
  display: flex;
  align-items: center;
  text-align: left;
  &:hover {
    color: ${props => props.invertColor ? 'inherit' : '#fff'}
  }
`;

export const colors = [
  {
    code: '8017',
    name: <span>8017</span>,
    color: '#46322d',
  },
  {
    code: '7021',
    name: <span>7021</span>,
    color: '#2f3234',
  },
  {
    code: '9005',
    name: <span>9005</span>,
    color: '#0e0e10',
  },
  {
    code: '7016',
    name: <span>7016</span>,
    color: '#383e42',
  },
  {
    code: '8019',
    name: <span>8019</span>,
    color: '#3d3635',
  },
  {
    code: '7040',
    name: <span>7040</span>,
    color: '#9da3a6',
  },
  {
    code: '7030',
    name: <span>7030</span>,
    color: '#0071a3',
  },
  {
    code: '7016SM',
    name: '7016SM',
    color: '#383e42',
  },
  {
    code: '7016SD',
    name: <span>7016SD</span>,
    color: '#383e42',
  },
  {
    code: '9005SM',
    name: <span>9005SM</span>,
    color: '#0e0e10',
  },
  {
    code: '6005',
    name: <span>6005</span>,
    color: '#114232',
  },
  {
    code: '7024',
    name: <span>7024</span>,
    color: '#474a51',
  },
  {
    code: '8017SM',
    name: <span>8017SM</span>,
    color: '#46322d',
  },
  {
    code: '9005SD',
    name: <span>9005SD</span>,
    color: '#0e0e10',
  },
  {
    code: '7021SD',
    name: <span>7021SD</span>,
    color: '#2f3234',
  },
  {
    code: '9006',
    name: <span>9006</span>,
    color: '#9898a8',
  },
  {
    code: '5010',
    name: <span>5010</span>,
    color: '#004f7c',
  },
  {
    code: '3004',
    name: <span>3004</span>,
    color: '#6b1c23',
  },
  {
    code: '3020',
    name: <span>3020</span>,
    color: '#c2855f',
  },
  {
    code: '9010',
    name: <span>9010</span>,
    color: '#eeebe1',
    invertColor: true,
  },
  {
    code: '9010SD',
    name: <span>9010SD</span>,
    color: '#eeebe1',
    invertColor: true,
  },
  {
    code: '3004SD',
    name: <span>3004SD</span>,
    color: '#6b1c23',
  },
  {
    code: '9007SD',
    name: <span>9007SD</span>,
    color: '#646870',
  },
  {
    code: '1028',
    name: <span>1028</span>,
    color: '#ff9b00',
  },
];

const ColorModule = () => {
  const formik = useFormikContext();
  const { t } = useTranslation();

  if (!formik.values.color || formik.values.moduleColor) return null;

  const rendered = colors.map(c =>
    <Button key={c.code} type="button" className="btn" color={c.color} invertColor={c?.invertColor}
            onClick={() => formik.setFieldValue('moduleColor', c.code)}>
      {c.name}
    </Button>)

  return (
    <Container>
      <div>
        <BackButton type="button" className="btn btn-light" onClick={() => formik.setFieldValue('color', '')} data-sentry="backToColor">
          <FontAwesomeIcon icon={faAngleLeft}/>
          {t('colorChooseBack')}
        </BackButton>
      </div>
      <div>
        <Desc>{t('moduleColorChoose')}</Desc>
      </div>
      <Buttons>
        {rendered}
      </Buttons>
    </Container>
  );
};

export default ColorModule;
