import {useFormikContext} from 'formik';
import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import LeftPole from './LeftPole';
import RightPole from './RightPole';
import Body from './Body';
import SpaceFromOtherProjects from '../SpaceFromOtherProjects';

const RenderContainer = styled.div`
  display: flex;
  width: 100%;
`;

const Gateway = ({ containerHeight }) => {
  const formik = useFormikContext();
  const scale = useSelector((s) => s.app.scale);
  const heightScaled = formik.values.gateway.height / scale;

  if (formik.values.gateway.renderDesign) {
    return (
      <div
        className="col"
        style={{ background: '#ffffff' }}
      >
        <RenderContainer
          id="gatewayRender"
          style={{
            height: `${heightScaled}px`,
            background: '#ffffff',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          {formik.values.span.renderDesign && (
          <SpaceFromOtherProjects
            containerHeight={containerHeight}
            type="span"
            side={'spaceSideLeft'}
          />
          )}
          <LeftPole />
          <Body design={formik.values.gateway} scale={scale} projectType="gateway" />
          <RightPole />
          {formik.values.gate.renderDesign && (
          <SpaceFromOtherProjects
            containerHeight={containerHeight}
            type="gate"
            side={'spaceSideRight'}
          />
          )}
        </RenderContainer>
      </div>
    );
  }
  return (
    <div className="col" />
  );
};

export default Gateway;
