import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1.4rem;
`;

const Size = styled.span`
  color: #fff;
  display: block;
`;

const RodSupplement = ({ size, scale, automation = false }) => {
  const rodHeightScaled = size / scale;
  const backgroundColor = size > 302 ? '#cb4a4a' : '#313639d9';

  return (
    <Container style={{
      height: rodHeightScaled,
      background: backgroundColor,
    }}
    >
      <Size>{`${size} mm`}</Size>
    </Container>
  );
};

export default RodSupplement;
