import React from 'react';
import styled from 'styled-components';

const RodContainer = styled.div`
  ${(props) => props.notGateTilting && 'position: relative; z-index: 13;'}
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #000000;
  padding: 0 1.4rem;
`;

const Size = styled.span`
  color: #fff;
  display: block;
`;

const Rod = ({
  index, size, scale, color, fenceType = null
}) => {
 const notGateTilting = fenceType !== 'gateTilting' && fenceType !== 'singleLeafGate' && fenceType !== 'foldingGate';
  return (
    <RodContainer
      style={{
        height: size / scale,
        background: index === 2 && color ? color : '#313639',
      }}
      notGateTilting={notGateTilting}
    >
      <Size>{`${size} mm`}</Size>
    </RodContainer>
  );
};

export default Rod;
