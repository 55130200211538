import React from 'react';
import styled from 'styled-components';


import Automation from '../../Automation';
import Rods from '../../Rods';

const Top = styled.div`
  background-image: url('img/gateTilting/top.png');
  background-size: contain;
  height: 80px;
  width: 100%;
`;

const Bottom = styled.div`
  background-image: url('img/gateTilting/bottom.png');
  background-size: auto;
  height: 80px;
  width: 100%;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(75% - 52px - 35px);
  position: relative;
`;

const Middle = ({ design, scale, projectType }) => (
  <Container>
    <Top />
    <Rods
      design={design}
      scale={scale}
      projectType={projectType}
      fenceType="gateTilting"
    />
    <Bottom />
    <Automation/>
  </Container>
);

export default Middle;
