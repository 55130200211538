import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Provider } from 'react-redux';
import { createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';

import appReducer from './reducers';
import * as serviceWorker from './serviceWorker';
import App from './App';

import 'Translation/config';

const store = createStore(
  appReducer,
  composeWithDevTools(),
);

Sentry.init({ dsn: 'https://a8805a024b8a492489321a17065952a1@o380431.ingest.sentry.io/5374608' });

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
