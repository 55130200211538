import React from 'react';
import {useFormikContext} from 'formik';
import styled from 'styled-components';

import {onPropertyChange} from 'Design/Design';
import ExcludeButton from 'Project/ExcludeButton';
import { useTranslation } from 'react-i18next';

const SelectGate = ({type}) => {
  const formik = useFormikContext();
  if (type !== 'gate') return null;
  const change = (e) => {
    onPropertyChange(formik, 'gate', 'gateType', e.currentTarget.value);
    formik.setFieldValue(`${type}.chosen`, true)
  };
  const { t } = useTranslation();

  return (
    <Container>
      <GateSelect
        className="form-control"
        id="gateType"
        value={formik.values.gate.gateType}
        onChange={change}
        name="gate.gateType"
        onBlur={formik.handleBlur}
      >
        <option value="" disabled>{t('chooseGate')}:</option>
        <option value="gateSelfSupporting">{t('gateSelfSupporting')}</option>
        <option value="gateTilting">{t('gateTilting')}</option>
        <option value="singleLeafGate">{t('singleLeafGate')}</option>
        <option value="foldingGate">{t('foldingGate')}</option>
        <option value="nonSymmetricalGate">{t('nonSymmetricalGate')}</option>
      </GateSelect>
      <ExcludeButton type={type}/>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
`;

const GateSelect = styled.select`
  border-radius: 30px;
  background: #efeff0;
  color: #000000;
  margin-right: 2rem;

  &:focus {
    outline: none;
    border: none;
    box-shadow: none;
    background: #efeff0;
    color: #000000;
  }
`;

export default SelectGate;
